import React, {useEffect} from "react";

// this method is purposefully for handling request to server side applications
const useRequestHttp = (request) => {

    const [payload, setPayload] = React.useState();
    const [isLoading, setIsLoading] = React.useState(true);

    useEffect(() => {
        let mounted = true;


        if (request.requestStatus) {
            // Handling GET request functions
            if (request.method === "GET") {
                if(request.headers) {
                    const myRequest = new Request(request.url, {
                        method: request.method,
                        headers: request.headers});
                    const loadData = async () => {
                        let response = await fetch(myRequest);
                        if (response.status === 204) {
                            setIsLoading(false)
                            return response.status
                        } else {
                            let data = await response.json();
                            if(mounted) {
                                return data
                            }
                        }
                    };
                    setIsLoading(false);
                    loadData().then(result => setPayload(result));
                } else {
                    const loadData = async () => {
                        let response = await fetch(request.url);
                        if (response.status === 204) {
                            setIsLoading(false)
                            return response.status
                        } else {
                            let data = await response.json();
                            if(mounted) {
                                return data
                            }
                        }
                    };
                    setIsLoading(false);
                    loadData().then(result => setPayload(result));
                }
            } else if (request.method === "POST") {
                (async () => {
                    const rawResponse = await fetch(request.url, {
                        method: request.method,
                        headers: request.headers,
                        body: JSON.stringify(request.body)
                    });
                    if (rawResponse.status === 204) {
                        setIsLoading(false);
                    } else {
                        const result = {
                            content: await rawResponse.json(),
                            status: rawResponse.status
                        };
                        // @ts-ignore
                        setPayload(result);
                        setIsLoading(false);
                    }
                })()
            }

            // eslint-disable-next-line
        }
        return () => {
            mounted = false;
        };
        // eslint-disable-next-line
    }, [request.requestState]);

    return [payload, isLoading]
};

export {
    useRequestHttp
};

import React, { useContext, useRef, useState } from "react";
import { Field, Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import _css from "./SignIn.module.scss";
import variants from "sass/colors.module.scss";
import { Alert, Grid, GridItem, List, ListItem, ListVariant, Spinner } from "@patternfly/react-core";
import { ACCOUNT_LOGIN_LINK } from "services/urls";
import { SessionsContext } from "contexts";
import { Link } from "react-router-dom"
import { EyeIcon } from "@patternfly/react-icons";
import { InputErrors } from "components";
import { push } from "react-router-redirect";

interface Props {
    isNav?: boolean
    toggleSideBarFromSigInForm?: any
}

const SignInForm: React.FC<Props> = ({
    isNav,
    toggleSideBarFromSigInForm
}) => {


    const required = value => (value ? undefined : value);
    const { setSess } = useContext(SessionsContext);
    const [loading, setLoading] = useState<boolean>(false);
    const [isFailedLogin, setFailedLogin] = useState<boolean>(false);
    const [isFailedAccount, setFailedAccount] = useState<boolean>(false);
    const [isServerError, setIsServerError] = useState<boolean>(false);
    const passwordRef = useRef<any>();

    const onSubmit = (values) => {
        setLoading(true)
        const payload = {
            "username": values.login_username,
            "password": values.login_password
        }
        fetch(ACCOUNT_LOGIN_LINK, {
            headers: {
                "content-type": "application/json",
            },
            method: "POST",
            body: JSON.stringify(payload)
        })
            .then((response) => [response.status, response.json()])
            .then(async (data) => {
                localStorage.removeItem("x-access-token")
                switch (data[0]) {
                    case 200:
                        handleSession(await data[1])
                        setLoading(false)
                        break
                    case 401:
                        setFailedLogin(true)
                        setLoading(false)
                        break
                    case 404:
                        setFailedAccount(true)
                        setLoading(false)
                        break
                    case 409:
                        handleSession(await data[1])
                        setLoading(false)
                        break
                    case 500:
                        setIsServerError(true)
                        setLoading(false)
                }
            })
    }

    const handleSession = (data) => {
        localStorage.setItem("x-access-token", data["x-access-token"])
        setSess(data["x-access-token"])
        setLoading(false)
    }

    const togglePasswordDisplay = (ref) => {
        switch (ref.current.type) {
            case "password":
                ref.current.type = "text"
                break
            case "text":
                ref.current.type = "password"
                break
        }
    }

    return (
        <React.Fragment>
            <FinalForm
                onSubmit={onSubmit}
                subscription={{
                    submitting: true,
                }}
                mutators={{
                    // potentially other mutators could be merged here
                    ...arrayMutators
                }}
                validate={values => {
                    const errors = {};
                    if (!values.login_username) {
                        errors["login_username"] = "L'identitifiant du compte est obligatoire.";
                    }
                    if (!values.login_password) {
                        errors["login_password"] = "Le mot de passe est obligatoire.";
                    }
                    return errors;
                }}
            >
                {({ handleSubmit }) => (
                    <form onSubmit={handleSubmit} className={_css.container}>
                        <Grid hasGutter>
                            <GridItem>
                                <Field
                                    name="login_username"
                                    validate={required}
                                    subscription={{
                                        value: true,
                                        active: true,
                                        error: true,
                                        touched: true
                                    }}
                                >
                                    {({ input, meta }) => (
                                        <label htmlFor="login_username">
                                            <input
                                                className={[
                                                    _css.input,
                                                    (meta.error && meta.touched) && variants.error_input
                                                ].join(" ")}
                                                type="text"
                                                id="login_username"
                                                onBlur={input.onBlur}
                                                onChange={input.onChange}
                                                name={input.name}
                                                placeholder={"Identifiant du compte"}
                                            />
                                            {(meta.error && meta.touched) && <InputErrors message={meta.error} />}
                                        </label>
                                    )}
                                </Field>
                            </GridItem>
                            <GridItem lg={8}>
                                <Field
                                    name="login_password"
                                    validate={required}
                                    subscription={{
                                        value: true,
                                        active: true,
                                        error: true,
                                        touched: true
                                    }}
                                >
                                    {({ input, meta }) => (
                                        <label htmlFor="login_password">
                                            <div className={_css.password_box}>
                                                <input
                                                    className={[
                                                        _css.input,
                                                        (meta.error && meta.touched) && variants.error_input
                                                    ].join(" ")}
                                                    type="password"
                                                    id="login_password"
                                                    onBlur={input.onBlur}
                                                    onChange={input.onChange}
                                                    name={input.name}
                                                    placeholder={"Mot de passe: Attention les majuscules sont prises en compte."}
                                                    ref={passwordRef}
                                                />
                                                <EyeIcon onClick={() => togglePasswordDisplay(passwordRef)} />
                                            </div>
                                            {(meta.error && meta.touched) && <InputErrors message={meta.error} />}
                                        </label>
                                    )}
                                </Field>
                            </GridItem>
                            <GridItem lg={4}>
                                <button
                                    type={"submit"}
                                    className={_css.login_btn}
                                >
                                    {loading ? <Spinner size={"sm"} color={"#fff"} /> : String("Se connecter")}
                                </button>
                            </GridItem>
                            {isFailedLogin &&
                                <GridItem>
                                    <Alert variant="danger" isInline title="Identifiant ou mot de passe fourni incorrect." />
                                </GridItem>}
                            {isFailedAccount &&
                                <GridItem>
                                    <Alert variant="danger" isInline title="L'utilisateur n'existe pas." />
                                </GridItem>}
                            {isServerError &&
                                <GridItem>
                                    <Alert variant="danger" isInline title="Oups, nous sommes désolés! Il y a une erreur du serveur." />
                                </GridItem>}
                            <GridItem>
                                <List variant={ListVariant.inline}>
                                    <ListItem>
                                        {isNav &&
                                            <Link
                                                onClick={() => {
                                                    push("/password.recovery")
                                                    toggleSideBarFromSigInForm(false)
                                                }}
                                                style={{ color: "#E24307" }}
                                            >
                                                Mot de passe oublié ?
                                            </Link>}
                                        {!isNav &&
                                            <Link
                                                to={"/password.recovery"}
                                                style={{ color: "#E24307" }}
                                            >
                                                Mot de passe oublié ?
                                            </Link>}
                                    </ListItem>
                                </List>
                            </GridItem>
                        </Grid>
                    </form>
                )}
            </FinalForm>
        </React.Fragment>
    )
}

export { SignInForm };

import { BASE } from "../services/urls";

export const TransferHours = async (values: any) => {

    const token = localStorage.getItem("x-imperso") || localStorage.getItem('x-access-token')

    const options: any = {
        method: "POST",
        headers: {
            "x-access-token": token,
            "content-type": "application/json",
        },
        body: JSON.stringify(values)
    };

    const response = await fetch(`${BASE}/transactions/sessions/transfer/hours`, options);
    const result = await response.json();

    if (!response.ok) throw new Error(result.message)
}
import React, { useContext, useEffect, useState } from "react";
import _css from "./MySpaceHeader.module.scss"
import {
    Bullseye,
    Dropdown,
    DropdownItem,
    DropdownToggle,
    List,
    ListItem,
    ListVariant,
    Title,
    Truncate
} from "@patternfly/react-core";
import { push } from 'react-router-redirect';
import { useLocation } from "react-router-dom";
import { CaretDownIcon } from '@patternfly/react-icons'
import { SessionsContext } from "contexts";
import { decodeJwtToken } from "modules/security"

interface Props {
    backgroundColor?: string
    color?: string
}

const MySpaceHeader: React.FC<Props> = (props) => {

    const {
        backgroundColor,
        color
    } = props;
    const location = useLocation();
    const [isOpenTransactionsMenu, setIsOpenTransactionsMenu] = useState<boolean>(false);
    const [isOpenOffersMenu, setIsOpenOffersMenu] = useState<boolean>(false);
    const { sess } = useContext(SessionsContext);
    const [userData, setUserData] = useState<any>();


    const onToggleTransactionsMenu = isOpen => setIsOpenTransactionsMenu(isOpen);
    const onToggleOffersMenu = isOpen => setIsOpenOffersMenu(isOpen);

    const onSelectTransactionsMenu = () => {
        setIsOpenTransactionsMenu(!isOpenTransactionsMenu)
        onFocusTransactionsMenu();
    };

    const onSelectOffersMenu = () => {
        setIsOpenOffersMenu(!isOpenOffersMenu)
        onFocusOffersMenu();
    };

    const onFocusTransactionsMenu = () => {
        const element = document.getElementById('toggle-id');
        // @ts-ignore
        element.focus();
    };

    const onFocusOffersMenu = () => {
        const element = document.getElementById('toggle-offers');
        // @ts-ignore
        element.focus();
    };

    const dropDownTransactions = [
        <DropdownItem key="list_transactions" onClick={() => push("/space")}>
            Liste des transactions effectuées
        </DropdownItem>,
        <DropdownItem
            key="transfer_hours"
            onClick={() => push("/space/hours/transfer")}
        >
            Transférer des heures
        </DropdownItem>,
    ];

    const dropDownOffers = [
        <DropdownItem key="list_offers" onClick={() => push("/space/offers")}>
            Mes offres
        </DropdownItem>,
        // <DropdownItem key="list_offers_favourites" onClick={() => push("/space/offers/favourites")}>
        //     Mes offres favorites
        // </DropdownItem>
    ];

    useEffect(() => {
        sess && setUserData(decodeJwtToken(sess))
    }, [sess])

    return (
        <React.Fragment>
            {sess &&
                <header
                    className={_css.container}
                    style={{
                        backgroundColor: backgroundColor,
                        color: color
                    }}
                >
                    <List variant={ListVariant.inline} className={_css.list}>
                        <ListItem style={{ display: "flex" }}>
                            <Title headingLevel={"h2"}>
                                <Truncate className={_css.account_name} content={userData?.first_name} />
                            </Title>
                        </ListItem>
                        <ListItem
                            className={(location.pathname === "/space" ||
                                location.pathname === "/space/hours/transfer") ?
                                _css.active_link : ""}
                        >
                            <Dropdown
                                onSelect={onSelectTransactionsMenu}
                                toggle={
                                    <DropdownToggle
                                        id="toggle-id"
                                        onToggle={onToggleTransactionsMenu}
                                        toggleIndicator={CaretDownIcon}
                                    >
                                        <b>Transactions</b>
                                    </DropdownToggle>
                                }
                                isOpen={isOpenTransactionsMenu}
                                dropdownItems={dropDownTransactions}
                            />
                        </ListItem>
                        <ListItem
                            className={(
                                (location.pathname === "/space/offers") ||
                                (location.pathname === "/space/offers/favourites")) ?
                                _css.active_link : ""}
                        >
                            <Dropdown
                                onSelect={onSelectOffersMenu}
                                toggle={
                                    <DropdownToggle
                                        id="toggle-offers"
                                        onToggle={onToggleOffersMenu}
                                        toggleIndicator={CaretDownIcon}
                                    >
                                        <b>Offres</b>
                                    </DropdownToggle>
                                }
                                isOpen={isOpenOffersMenu}
                                dropdownItems={dropDownOffers}
                            />
                        </ListItem>
                        <ListItem
                            onClick={() => push("/space/profile")}
                            className={(location && location.pathname === "/space/profile") ? _css.active_link : ""}
                        >
                            <Bullseye>Mon profil</Bullseye>
                        </ListItem>
                        {(userData && userData?.roles.some(role => role.name === "admin")) &&
                            <ListItem
                                onClick={() => push("/space/admin")}
                                className={(location && location.pathname === "/space/admin") ? _css.active_link : ""}
                            >
                                <Bullseye>Administration</Bullseye>
                            </ListItem>}
                    </List>
                </header>}
        </React.Fragment>
    )
}

MySpaceHeader.defaultProps = {
    backgroundColor: "#f5f5f5",
    color: "#232323"
}

export default MySpaceHeader;

import React, {useRef, useState} from "react";
import {Field, Form as FinalForm} from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import _css from "./RecoverPassword.module.scss";
import variants from "sass/colors.module.scss";
import {Alert, AlertActionCloseButton, Button, Grid, GridItem, Spinner, Text, Title} from "@patternfly/react-core";
import {InputErrors} from "components";
import {RECOVER_ACCOUNT_PASSWORD} from "services/urls";

type Message = {
    variant: "success" | "danger" | "default" | "warning" | "info" | undefined
    message: string
} | undefined

const RecoverAccountForm: React.FC = () => {

    const required = value => (value ? undefined : value);
    const [ submitting, isSubmitting ] = useState<boolean>(false);
    const [ message, setMessage ] = useState<Message>();
    const formRef = useRef<any>();

    const resetForm = (form) => {
        // @ts-ignore
        Array.from(form.elements).forEach((input) => input.value = '');
    }

    const onSubmit = (values, form) => {
        isSubmitting(true)
        fetch(RECOVER_ACCOUNT_PASSWORD, {
            method : "POST",
            headers : {
                "content-type" : "application/json",
                "accept" : "application/json"
            },
            body : JSON.stringify(values)
        })
            .then((response) => response.status)
            .then((data) => {
                [200, 201, 204, 202].includes(data) ?
                    setMessage({
                        "variant" : "success",
                        "message" : "Demande traitée, veuillez confirmer à partir de votre adresse e-mail."
                    }) : data === 404 ?
                    setMessage({
                        "variant" : "warning",
                        "message" : "Le compte avec l'adresse e-mail indiquée n'existe pas."
                    })
                    :
                    setMessage({
                        "variant" : "danger",
                        "message" : "Oop, nous sommes désolés erreur de serveur échouée par l'opérateur, réessayez si cela persiste, veuillez contacter le support technique!"
                    })
            })
            .finally(() => {
                isSubmitting(false)
                resetForm(formRef.current)
            })
    }

    return (
        <React.Fragment>
            <FinalForm
                onSubmit={onSubmit}
                subscription={{
                    submitting : true,
                }}
                mutators={{
                    // potentially other mutators could be merged here
                    ...arrayMutators
                }}
                validate={values => {
                    const errors = {};
                    const re = /\S+@\S+\.\S+/;
                    if (!values.email) {
                        errors["email"] = "L'adresse e-mail est obligatoire";
                    }
                    if (values.email && !(re.test(values.email))) {
                        errors["email"] = "L'adresse mail est invalide";
                    }
                    return errors;
                }}
            >
                {({handleSubmit}) => (
                    <form
                        onSubmit={handleSubmit}
                        className={_css.container}
                        ref={formRef}
                    >
                        <Grid hasGutter>
                            <GridItem>
                                <Title headingLevel={"h1"} className={_css.title}>
                                    Obtenir un nouveau mot de passe.
                                </Title>
                                <Grid hasGutter className={_css.form_components}>
                                    <GridItem lg={12}>
                                        <Field
                                            name="email"
                                            validate={required}
                                            subscription={{
                                                value: true,
                                                active: true,
                                                error: true,
                                                touched: true
                                            }}
                                        >
                                            {({ input, meta }) => (
                                                <label htmlFor="email">
                                                    <input
                                                        className={[
                                                            _css.input,
                                                            (meta.error && meta.touched) && variants.error_input
                                                        ].join(" ")}
                                                        type="text"
                                                        id="email"
                                                        onBlur={input.onBlur}
                                                        onChange={input.onChange}
                                                        name={input.name}
                                                        placeholder={"Identifiant du compte"}
                                                    />
                                                    {(meta.error && meta.touched) &&
                                                    <InputErrors message={meta.error}/>
                                                    }
                                                </label>
                                            )}
                                        </Field>
                                    </GridItem>
                                    {(!submitting && message) &&
                                    <GridItem>
                                        <Alert isInline variant={message.variant || "default"}
                                               title={"Changer le mot de passe du compte"}
                                               actionClose={<AlertActionCloseButton
                                                   onClose={() => setMessage(undefined)}/>}>
                                            <Text>
                                                {message.message}
                                            </Text>
                                        </Alert>
                                    </GridItem>}
                                    <GridItem>
                                        <Button isDisabled={submitting} type={"submit"}>
                                            {submitting ? <Spinner size="sm"/> : <span>Mettre à jour</span>}
                                        </Button>{' '}
                                        <Button component="a" href="/" isDisabled={submitting}>
                                            {submitting ? <Spinner size="sm"/> : <span>Annuler</span>}
                                        </Button>
                                    </GridItem>
                                </Grid>
                            </GridItem>
                        </Grid>
                    </form>
                )}
            </FinalForm>
        </React.Fragment>
    )
}

export {RecoverAccountForm};

export const APP = "http://localhost:3001"
export const BASE = "https://api.v2.banqueapitons.org/api/v1";
export const DECODE_SESSION_TOKEN = `${BASE}/authentications/decode/session-token`
export const ACCOUNT_LOGIN_LINK = BASE + "/authentications/sign_in";
export const UPDATE_SESSION_PASSWORD = BASE + "/authentications/session/update_password";
export const CHECK_TOKEN_VALID = BASE + "/authentications/check_token_validity";
export const CREATE_CHRONIC = BASE + "/communiques/";
export const FETCH_CHRONICS = BASE + "/communiques/";
export const FETCH_CHRONICS_COUNTS = BASE + "/communiques/count";
export const FETCH_ARTICLES = BASE + "/communiques/";
export const FETCH_USERS = BASE + "/users/";
export const SEARCH_USERS = BASE + "/users/search";
export const DELETE_CHRONICS = BASE + "/communiques/";
export const DELETE_ARTICLES = BASE + "/communiques/";
export const COUNT_CHRONICS = BASE + "/communiques/count";
export const COUNT_ARTICLES = BASE + "/communiques/count";
export const COUNT_USERS = BASE + "/users/count";
export const CREATE_ARTICLES = BASE + "/communiques/";
export const CREATE_ARTICLES_SECTIONS = BASE + "/communiques/sections";
export const FETCH_ARTICLES_SECTIONS = BASE + "/communiques/sections";
export const USER_HOURS = BASE + "/users/hours"
export const QUERY_USER_SESSIONS = BASE + "/authentications/sessions";
export const ROLES = BASE + "/roles/";
export const CHART_HOURS = BASE + "/users/hours_chart";
export const QUERY_OFFERS = BASE + "/offers/";
export const QUERY_USER_SESSION_OFFERS = BASE + "/offers/user/session";
export const QUERY_OFFERS_COUNT = BASE + "/offers/count";
export const QUERY_OFFERS_CATEGORIES = BASE + "/offers/categories";
export const QUERY_OFFERS_SEARCH = BASE + "/offers/search";
export const QUERY_FETCH_LOCATIONS_CANADA = BASE + "/locations/ca";
export const QUERY_SEARCH_LOCATIONS_CANADA = BASE + "/locations/search/ca";
export const QUERY_FETCH_SESSION_TRANSACTIONS = BASE + "/transactions/sessions";
export const REGISTER_PUBLIC_USERS = BASE + "/authentications/register";
export const QUERY_GET_APPOINTMENTS = BASE + "/appointments/";
export const QUERY_APPOINTMENTS_COUNT = BASE + "/appointments/count";
export const QUERY_SESSION_OFFERS_COUNT = BASE + "/offers/session/count";
export const QUERY_GET_USER_APPOINTMENTS = BASE + "/appointments/users/chosen";
export const QUERY_CHOSE_APPOINTMENTS = BASE + "/appointments/chose";
export const TOGGLE_SESSION_OFFER_STATE = (oid, state) => `${BASE}/offers/session/toggle-state/${oid}?state=${state}`;
export const DELETE_SESSION_OFFER = (oid) => `${BASE}/offers/delete/${oid}`;
export const DELETE_SESSION_FAVOURITE_OFFER = (oid) => `${BASE}/offers/remove/session/favourite/${oid}`;
export const COUNT_SESSION_FAVOURITE_OFFERS = `${BASE}/offers/session/favourites/count`;
export const UPDATE_SESSION_ACCOUNT_INFORMATION = `${BASE}/users/session/update_information`;
export const UPDATE_SESSION_ACCOUNT_CONTACT = `${BASE}/users/session/update_contact`;
export const FETCH_SESSION_FAVOURITE_OFFERS = `${BASE}/offers/session/favourites`;
export const FETCH_TRANSACTION_QUALITIES = `${BASE}/transactions/user/qualities`;
export const TRANSFER_SESSION_HOURS = `${BASE}/transactions/sessions/transfer/hours`;
export const RECOVER_ACCOUNT_PASSWORD = `${BASE}/authentications/recover_password`;
export const RESET_ACCOUNT_PASSWORD = `${BASE}/authentications/reset_password`;

import {BASE} from "../services/urls";

export const RESET_PUBLIC_PASSWORD = async ( data: {token, password} ) => {

    const options:any = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'accept': 'application/json',
            'lang': localStorage.getItem('i18nextLng'),
        },
        body: JSON.stringify({
            "password": data.password,
            "token": data.token
        })
    };

    const response = await fetch(`${BASE}/authentications/reset_password`, options);
    const result = await response.json();

    if (!response.ok) throw new Error(result.message)
}

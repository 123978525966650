import React from 'react';
import {RouteComponentProps} from 'react-router-dom';
import {RequestServices} from "services";
import {push} from "react-router-redirect";
import useRequestFetch from "react-requests-fetch";

interface Props  {
    url: string
}

type CombinedProps = Props & RouteComponentProps

const AccountSignInChecker = (ComposedComponent) => {

    const Checker: React.FC<CombinedProps> = (props) => {

        const accessToken = localStorage.getItem("x-access-token");
        const [ payload, isLoading ] = useRequestFetch(RequestServices.checkTokenValid(String(accessToken)));


        const render = () => {
            if (accessToken !== null) {
                if (!isLoading && payload === 204) {
                    push("/admin/space")
                    return
                }
                if (payload !== undefined && (!isLoading && payload !== 204)) {
                    localStorage.removeItem("x-access-token")
                    push("/admin/sign_in")
                    return
                }
            } else {
                return <ComposedComponent {...props} />
            }
        };

        return (
            <>
                {render()}
            </>
        )

    };
    return Checker
};

export {
    AccountSignInChecker
}

import React, { useEffect, useRef, useState } from "react";
import { Field, Form as FinalForm } from 'react-final-form';
import MaskedInput from 'react-text-mask'; // Import MaskedInput
import arrayMutators from 'final-form-arrays';
import formatString from "format-string-by-pattern";
import _css from "./Login.module.scss";
import variants from "sass/colors.module.scss";
import { Alert, AlertActionCloseButton, Grid, GridItem, Spinner, Text, Title } from "@patternfly/react-core";
import useRequestFetch from "react-requests-fetch";
import { REGISTER_PUBLIC_USERS } from "services/urls";
import ConfirmRegistration from "./confirm_registration/ConfirmRegistration";
import { HomeArticleHeader } from "../../headers/home_article_header/HomeArticleHeader";
import { EyeIcon } from "@patternfly/react-icons";
import { InputErrors } from "components";

interface Props {
    session: React.SetStateAction<any>
}

const UserRegisterForm: React.FC<Props> = () => {

    const required = value => (value ? undefined : value);
    const [loading, setLoading] = useState<boolean>(false);
    const [registerUserPayload, setRegisterUser] = useRequestFetch({});
    const [failMessage, setFailMessage] = useState<string | undefined>(undefined);
    const [successSubmitted, setSuccessSubmitted] = useState<boolean>(false);
    const passwordRef = useRef<any>();
    const resetPasswordRef = useRef<any>();
    const phoneMask = {name: "phone_number", "parse": "(418) 123-4568"}

    const onSubmit = (values) => {
        setLoading(true)
        setRegisterUser({
            uri: REGISTER_PUBLIC_USERS,
            method: 'POST',
            headers: {
                "content-type": "application/json"
            },
            body: values
        })
    }

    useEffect(() => {

        if (registerUserPayload && registerUserPayload.statusCode) {
            if (registerUserPayload.statusCode === 409) {
                setFailMessage(registerUserPayload.payload.message)
                setLoading(false)
            } else if (registerUserPayload.statusCode === 500) {
                setFailMessage("Erreur serveur! veuillez réessayer. Si le problème persiste, contactez l'administrateur.")
                setLoading(false)
            } else {
                setLoading(false)
                setFailMessage(undefined)
                setSuccessSubmitted(true)
            }
        }

    }, [registerUserPayload])

    const closeErrorMessage = () => {
        setFailMessage(undefined)
    }

    useEffect(() => {
        setSuccessSubmitted(false)
    }, [])

    const togglePasswordDisplay = (ref) => {
        switch (ref.current.type) {
            case "password":
                ref.current.type = "text"
                break
            case "text":
                ref.current.type = "password"
                break
        }
    }

    const getAge = (dateString) => {
        const today = new Date();
        const birthDate = new Date(dateString);
        let age = today.getFullYear() - birthDate.getFullYear();
        const m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    }

    return (
        <React.Fragment>
            {successSubmitted ?
                <ConfirmRegistration />
                :
                <React.Fragment>
                    <Title
                        headingLevel={"h3"}
                        size="2xl"
                        style={{
                            marginTop: "1rem"
                        }}
                    >
                        Voici ce qu’il faut savoir avant de vous inscrire à la Banque à pitons
                    </Title>
                    <br />
                    <HomeArticleHeader
                        title={"Inscription"}
                        optionWidth={2}
                    />
                    <br />
                    <FinalForm
                        onSubmit={onSubmit}
                        subscription={{
                            submitting: true,
                        }}
                        mutators={{
                            // potentially other mutators could be merged here
                            ...arrayMutators
                        }}
                        validate={values => {
                            const errors = {};
                            const re = /\S+@\S+\.\S+/;

                            if (!values.first_name) {
                                errors["first_name"] = "Le champ du prénom est obligatoire.";
                            }
                            if (!values.first_name) {
                                errors["last_name"] = "Le champ du nom de famille est obligatoire.";
                            }
                            if (!values.username) {
                                errors["username"] = "Le nom d'utilisateur est obligatoire.";
                            }
                            if (!(values.gender)) {
                                errors["gender"] = "Le genre est obligatoire."
                            }
                            if (!values.password) {
                                errors["password"] = "Le mot de passe est obligatoire."
                            }
                            if (values.password !== values.confirm_password) {
                                errors["confirm_password"] = "Le mot de passe de confirmation ne correspond pas au mot de passe.";
                            }
                            if (!values.confirm_password) {
                                errors["confirm_password"] = "Confirmation du mot de passe requise.";
                            }
                            if (!values.username) {
                                errors["username"] = "Le nom d'utilisateur est obligatoire.";
                            }

                            if (values.email && !(re.test(values.email))) {
                                errors["email"] = "L'adresse courriel est invalide.";
                            }
                            if (!values.email) {
                                errors["email"] = "Une adresse courriel valide est obligatoire.";
                            }
                            if (!values.phone_number) {
                                errors["phone_number"] = "Un numéro de téléphone valide est obligatoire."
                            }
                            if (values.date_of_birth) {
                                const age = getAge(values.date_of_birth);
                                if (age < 19) {
                                    errors["date_of_birth"] = "Malheureusement, vous êtes mineur, contactez la Banque à pitons pour les enregistrements des comptes au moins de 18 ans."
                                }
                            }
                            // console.log(values.last_name)
                            // console.log(values.gender)
                            return errors;
                        }}
                    >
                        {({ handleSubmit }) => (
                            <form onSubmit={handleSubmit} className={_css.container}>
                                <Grid hasGutter>
                                    <GridItem>
                                        <Grid hasGutter className={_css.form_components}>
                                            <GridItem lg={5}>
                                                <Grid hasGutter>
                                                    <Title headingLevel={"h3"} size="3xl">
                                                        Identification
                                                    </Title>
                                                    <GridItem lg={10} sm={12}>
                                                        <Field
                                                            name="first_name"
                                                            validate={required}
                                                            subscription={{
                                                                value: true,
                                                                active: true,
                                                                error: true,
                                                                touched: true
                                                            }}
                                                        >
                                                            {({
                                                                input,
                                                                meta
                                                            }) => (
                                                                <label htmlFor="first_name">
                                                                    <input
                                                                        className={[
                                                                            _css.input,
                                                                            (meta.error && meta.touched) && variants.error_input
                                                                        ].join(" ")}
                                                                        type="text"
                                                                        id="first_name"
                                                                        onBlur={input.onBlur}
                                                                        onChange={input.onChange}
                                                                        name={input.name}
                                                                        placeholder={"Prénom *"}
                                                                    />
                                                                    {(meta.error && meta.touched) &&
                                                                        <InputErrors message={meta.error} />}
                                                                </label>
                                                            )}
                                                        </Field>
                                                    </GridItem>
                                                    <GridItem lg={10} sm={12}>
                                                        <Field
                                                            name="last_name"
                                                            validate={required}
                                                            subscription={{
                                                                value: true,
                                                                active: true,
                                                                error: true,
                                                                touched: true
                                                            }}
                                                        >
                                                            {({
                                                                input,
                                                                meta
                                                            }) => (
                                                                <label htmlFor="last_name">
                                                                    <input
                                                                        className={[
                                                                            _css.input,
                                                                            (meta.error && meta.touched) && variants.error_input
                                                                        ].join(" ")}
                                                                        type="text"
                                                                        id="last_name"
                                                                        onBlur={input.onBlur}
                                                                        onChange={input.onChange}
                                                                        name={input.name}
                                                                        placeholder={"Nom *"}
                                                                    />
                                                                    {(meta.error && meta.touched) &&
                                                                        <InputErrors message={meta.error} />}
                                                                </label>
                                                            )}
                                                        </Field>
                                                    </GridItem>
                                                    <GridItem lg={10} sm={12}>
                                                        <Field
                                                            name="gender"
                                                            subscription={{
                                                                value: true,
                                                                active: true,
                                                                error: true,
                                                                touched: true
                                                            }}
                                                        >
                                                            {({
                                                                input,
                                                                meta
                                                            }) => (
                                                                <label htmlFor="gender">
                                                                    <select
                                                                        defaultValue={"N"}
                                                                        onChange={input.onChange}
                                                                        onBlur={input.onBlur}
                                                                        name={input.name}
                                                                        id="gender"
                                                                        className={[
                                                                            _css.input,
                                                                            (meta.error && meta.touched) && variants.error_input
                                                                        ].join(" ")}
                                                                    >
                                                                        <option value="N" disabled>
                                                                            Sélectionnez votre genre *
                                                                        </option>
                                                                        <option value="M">Homme</option>
                                                                        <option value="F">Femme</option>
                                                                        <option value="A">Autre</option>
                                                                    </select>
                                                                    {(meta.error && meta.touched) &&
                                                                        <InputErrors message={meta.error} />}
                                                                </label>
                                                            )}
                                                        </Field>

                                                    </GridItem>
                                                    <GridItem lg={10} sm={12}>
                                                        <Text>Date de naissance * </Text>
                                                        <Field
                                                            name="date_of_birth"
                                                            subscription={{
                                                                value: true,
                                                                active: true,
                                                                error: true,
                                                                touched: true
                                                            }}
                                                        >
                                                            {({
                                                                input,
                                                                meta
                                                            }) => (
                                                                <label htmlFor="date_of_birth">
                                                                    <input
                                                                        className={[
                                                                            _css.input,
                                                                            (meta.error && meta.touched) && variants.error_input
                                                                        ].join(" ")}
                                                                        type="date"
                                                                        id="date_of_birth"
                                                                        onBlur={input.onBlur}
                                                                        onChange={input.onChange}
                                                                        name={input.name}
                                                                        placeholder={"Date de naissance"}
                                                                    />
                                                                    {(meta.error && meta.touched) &&
                                                                        <InputErrors message={meta.error} />}
                                                                </label>
                                                            )}
                                                        </Field>
                                                    </GridItem>
                                                </Grid>
                                                <br />
                                                <Grid hasGutter>
                                                    <Title headingLevel={"h3"} size="3xl">
                                                        Accès et sécurité
                                                    </Title>
                                                    <GridItem lg={10} sm={12}>
                                                        <Field
                                                            name="username"
                                                            validate={required}
                                                            subscription={{
                                                                value: true,
                                                                active: true,
                                                                error: true,
                                                                touched: true
                                                            }}
                                                        >
                                                            {({
                                                                input,
                                                                meta
                                                            }) => (
                                                                <label htmlFor="username">
                                                                    <input
                                                                        className={[
                                                                            _css.input,
                                                                            (meta.error && meta.touched) && variants.error_input
                                                                        ].join(" ")}
                                                                        type="text"
                                                                        id="username"
                                                                        onBlur={input.onBlur}
                                                                        onChange={input.onChange}
                                                                        name={input.name}
                                                                        placeholder={"Nom d'utilisateur du compte *"}
                                                                    />
                                                                    {(meta.error && meta.touched) &&
                                                                        <InputErrors message={meta.error} />}
                                                                </label>
                                                            )}
                                                        </Field>
                                                    </GridItem>
                                                    <GridItem lg={10} sm={12}>
                                                        <Field
                                                            name="password"
                                                            validate={required}
                                                            subscription={{
                                                                value: true,
                                                                active: true,
                                                                error: true,
                                                                touched: true
                                                            }}
                                                        >
                                                            {({
                                                                input,
                                                                meta
                                                            }) => (
                                                                <label htmlFor="password">
                                                                    <div className={_css.password_box}>
                                                                        <input
                                                                            ref={passwordRef}
                                                                            className={[
                                                                                _css.input,
                                                                                (meta.error && meta.touched) && variants.error_input
                                                                            ].join(" ")}
                                                                            type="password"
                                                                            id="password"
                                                                            onBlur={input.onBlur}
                                                                            onChange={input.onChange}
                                                                            name={input.name}
                                                                            placeholder={"Mot de passe *"}
                                                                        />
                                                                        <EyeIcon
                                                                            onClick={() => togglePasswordDisplay(passwordRef)} />
                                                                    </
                                                                    div>
                                                                    {(meta.error && meta.touched) &&
                                                                        <InputErrors message={meta.error} />}
                                                                </label>
                                                            )}
                                                        </Field>
                                                    </GridItem>
                                                    <GridItem lg={10} sm={12}>
                                                        <Field
                                                            name="confirm_password"
                                                            validate={required}
                                                            subscription={{
                                                                value: true,
                                                                active: true,
                                                                error: true,
                                                                touched: true
                                                            }}
                                                        >
                                                            {({
                                                                input,
                                                                meta
                                                            }) => (
                                                                <label htmlFor="confirm_password">
                                                                    <div className={_css.password_box}>
                                                                        <input
                                                                            className={[
                                                                                _css.input,
                                                                                (meta.error && meta.touched) && variants.error_input
                                                                            ].join(" ")}
                                                                            type="password"
                                                                            id="confirm_password"
                                                                            onBlur={input.onBlur}
                                                                            onChange={input.onChange}
                                                                            name={input.name}
                                                                            placeholder={"Confirmez le mot de passe *"}
                                                                            ref={resetPasswordRef}
                                                                        />
                                                                        <EyeIcon
                                                                            onClick={() => togglePasswordDisplay(resetPasswordRef)} />
                                                                    </
                                                                    div>
                                                                    {(meta.error && meta.touched) &&
                                                                        <InputErrors message={meta.error} />}
                                                                </label>
                                                            )}
                                                        </Field>
                                                    </GridItem>
                                                </Grid>
                                            </GridItem>
                                            <GridItem lg={5}>
                                                <Grid hasGutter>
                                                    <Title headingLevel={"h3"} size="3xl">
                                                        Contact
                                                    </Title>
                                                    <GridItem lg={10} sm={12}>
                                                        <Text>
                                                            Veuillez fournir des coordonnées valides pour ce compte afin
                                                            que nous puissions vous contacter.
                                                        </Text>
                                                    </GridItem>
                                                    <GridItem lg={10} sm={12}>
                                                    <Field
                                                        component="input"
                                                        name={phoneMask.name}
                                                        parse={formatString(phoneMask.parse)}
                                                        placeholder={phoneMask.parse}
                                                        // className={[
                                                        //     _css.input,
                                                        //     // (meta.error && meta.touched) && variants.error_input
                                                        // ].join(" ")}
                                                        type="text"
                                                        id="phone_number"
                                                        // onBlur={input.onBlur}
                                                        // onChange={input.onChange}
                                                    >
                                                        {({
                                                                input,
                                                                meta
                                                            }) => (

                                                                <MaskedInput
                                                                    {...input}
                                                                    mask={[
                                                                        '(',
                                                                        /[1-9]/,
                                                                        /\d/,
                                                                        /\d/,
                                                                        ')',
                                                                        ' ',
                                                                        /\d/,
                                                                        /\d/,
                                                                        /\d/,
                                                                        '-',
                                                                        /\d/,
                                                                        /\d/,
                                                                        /\d/,
                                                                        /\d/
                                                                    ]}
                                                                    placeholder="(123) 456-7890"
                                                                    guide={false} // Prevents placeholder characters from being displayed
                                                                    onBlur={input.onBlur}
                                                                    name={input.name}
                                                                    className={[
                                                                        _css.input,
                                                                        (meta.error && meta.touched) && variants.error_input
                                                                    ].join(" ")}

                                                                    {...(meta.error && meta.touched) &&
                                                                                    <InputErrors message={meta.error} />}/>

                                                                // {(meta.error && meta.touched) &&
                                                                //             <InputErrors message={meta.error} />}
                                                                // <label htmlFor="phone_number">
                                                                //     <input
                                                                //         className={[
                                                                //             _css.input,
                                                                //             (meta.error && meta.touched) && variants.error_input
                                                                //         ].join(" ")}
                                                                //         type="text"
                                                                //         id="phone_number"
                                                                //         onBlur={input.onBlur}
                                                                //         onChange={input.onChange}
                                                                //
                                                                //         // placeholder={"Numéro de téléphone *"}
                                                                //         placeholder={phoneMask.parse}
                                                                //     />
                                                                //     {(meta.error && meta.touched) &&
                                                                //         <InputErrors message={meta.error} />}
                                                                // </label>
                                                            )}

                                                    </Field>
                                                        {/* <Field
                                                            component="input"
                                                            name="phone_number"
                                                            validate={required}
                                                            subscription={{
                                                                value: true,
                                                                active: true,
                                                                error: true,
                                                                touched: true
                                                            }}
                                                        >
                                                            {({
                                                                input,
                                                                meta
                                                            }) => (
                                                                <label htmlFor="phone_number">
                                                                    <input
                                                                        className={[
                                                                            _css.input,
                                                                            (meta.error && meta.touched) && variants.error_input
                                                                        ].join(" ")}
                                                                        type="text"
                                                                        id="phone_number"
                                                                        onBlur={input.onBlur}
                                                                        onChange={input.onChange}
                                                                        name={input.name}
                                                                        // placeholder={"Numéro de téléphone *"}
                                                                        placeholder={phoneMask.parse}
                                                                    />
                                                                    {(meta.error && meta.touched) &&
                                                                        <InputErrors message={meta.error} />}
                                                                </label>
                                                            )}
                                                        </Field> */}
                                                    </GridItem>
                                                    <GridItem lg={10} sm={12}>
                                                        <Field
                                                            name="email"
                                                            validate={required}
                                                            subscription={{
                                                                value: true,
                                                                active: true,
                                                                error: true,
                                                                touched: true
                                                            }}
                                                        >
                                                            {({
                                                                input,
                                                                meta
                                                            }) => (
                                                                <label htmlFor="email">
                                                                    <input
                                                                        className={[
                                                                            _css.input,
                                                                            (meta.error && meta.touched) && variants.error_input
                                                                        ].join(" ")}
                                                                        type="text"
                                                                        id="email"
                                                                        onBlur={input.onBlur}
                                                                        onChange={input.onChange}
                                                                        name={input.name}
                                                                        placeholder={"Courriel *"}
                                                                    />
                                                                    {(meta.error && meta.touched) &&
                                                                        <InputErrors message={meta.error} />}
                                                                </label>
                                                            )}
                                                        </Field>
                                                    </GridItem>
                                                    {failMessage &&
                                                        <GridItem lg={10}>
                                                            <Alert
                                                                variant="danger"
                                                                isInline
                                                                title={failMessage}
                                                                actionClose={
                                                                    <AlertActionCloseButton
                                                                        onClose={closeErrorMessage}
                                                                    />
                                                                }
                                                            />
                                                        </GridItem>}
                                                    <GridItem>
                                                        <button type={"submit"}>{loading ?
                                                            <Spinner size={"sm"} />
                                                            : String("Créer")}
                                                        </button>
                                                    </GridItem>
                                                </Grid>
                                            </GridItem>
                                        </Grid>
                                    </GridItem>
                                </Grid>
                            </form>
                        )}
                    </FinalForm>
                </React.Fragment>}
        </React.Fragment>
    )
}

export { UserRegisterForm };

import {BASE} from "../services/urls";

export const API_GET_SESSION_TRANSACTIONS = async ({ queryKey }) => {

    const token = localStorage.getItem("x-imperso") || localStorage.getItem('x-access-token')
    const { limit, page } = queryKey[1];

    const options: any = {
        method: 'GET',
        headers: {
            'accept': 'application/json',
            'lang': localStorage.getItem('i18nextLng'),
            "x-access-token": token
        }
    };

    const response = await fetch(`${BASE}/transactions/sessions?page=${page}&limit=${limit}`, options);
    if (!response.ok) throw new Error(JSON.stringify(response.text()))
    return response.json()
}
import React, {Fragment} from "react";
import {Grid, GridItem, Title, TitleSizes} from "@patternfly/react-core";

const ConfirmRegistration = () => {
    return (
        <Fragment>
            <Grid style={{
                margin : '2rem 0'
            }}>
                <GridItem>
                    <Title
                        style={{
                            textAlign : 'center'
                        }}
                        headingLevel={"h1"}
                        size={TitleSizes["2xl"]}
                    >
                        Bravo! La première étape d'inscription à la Banque à pitons a été effectuée.
                    </Title>
                </GridItem>
                <GridItem
                    style={{
                        textAlign : 'center'
                    }}
                >
                    <picture>
                        <img
                            sizes="(max-width: 1083px) 100vw, 1083px"
                            srcSet="
                                https://bap.s3.eu-de.cloud-object-storage.appdomain.cloud/44-layers_itxyud_c_scale,w_1083.png"
                            src="https://bap.s3.eu-de.cloud-object-storage.appdomain.cloud/44-layers_itxyud_c_scale,w_1083.png"
                            alt="bravo"/>
                    </picture>
                </GridItem>
            </Grid>
        </Fragment>
    )
}

export default ConfirmRegistration;

import React, {useEffect, useState} from "react";
import {FormGroup, Select, SelectDirection, SelectOption, SelectVariant} from "@patternfly/react-core";
import {RequestServices} from "services";
import useRequestFetch from "react-requests-fetch";

const RolesComponent:React.FC<any> = (props) => {

    const titleId = 'multi-typeahead-select-id';
    const [ selected, setSelected ] = useState<any>([]);
    const [ roles ] = useRequestFetch(RequestServices.queryRoles());
    const [ elements, setElements ] = useState<any>([]);
    const [isOpen, setIsOpen] = useState<boolean>(false)

    const select_options = elements.map((element, index) =>

        (<SelectOption
            key={index}
            value={element} />));

    const onSelectToggle = () => {
        setIsOpen(!isOpen)
    };

    const onSelectSelect = (event, selection) => {
        if (selected.includes(selection)) {
            setSelected(selected.filter(item => item !== selection))
        } else {
            setSelected([selection, ...selected])
        }
    };

    const clearSelectSelection = () => {
        setSelected([]);
    };

    const customFilter = e => {
        const input = e.target.value.toString();
        return input !== '' ? select_options.filter(option => option.props.value["title"].includes(input)) : select_options;
    };

    const handleChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
        props.input.onChange([target.value]); // instead of the default target.value
    };

    useEffect(() => {
        let payload:any = []
        if (roles) {
            roles.map((role) => {
                let userObject = {
                    id: role["id"],
                    title: role["name"],
                    toString: function () {
                        return `${role["name"]}`;
                    }
                }
                return payload.push(userObject)
            })
        }
        setElements(payload)
    }, [roles])

    useEffect(() => {
        props.input.onChange(selected)
        // eslint-disable-next-line
    }, [selected]);

    return(
        <FormGroup
            fieldId={props.input.name}
            validated={(props.meta.error && props.meta.touched) ? "error": "default"}
            autoComplete="off"
        >
            <Select
                name={props.input.name}
                variant={SelectVariant.typeaheadMulti}
                onToggle={onSelectToggle}
                onSelect={onSelectSelect}
                onChange={handleChange}
                value={props.input.values}
                onClear={clearSelectSelection}
                onFilter={customFilter}
                selections={selected}
                isOpen={isOpen}
                direction={SelectDirection.down}
                aria-labelledby={titleId}
                placeholder={"Roles"}
            >
                {select_options}
            </Select>
        </FormGroup>
    )
};

export {RolesComponent}

import React, {useEffect, useState, Fragment, FC, ChangeEvent} from "react";
import {FormGroup, Select, SelectOption, Spinner} from "@patternfly/react-core";
import {useQuery} from "react-query";
import {GetUserRoles} from "api";

const SelectRoleOption:FC<any> = (props) => {

    const titleId = 'typeahead-select-user-role';
    const [isOpen, setOpen] = useState<boolean>(false);
    const [selected, setSelected] = useState<any>(props.input.value ? props.input.value : null);
    const access_token = localStorage.getItem("x-access-token");


    const {data, isLoading} = useQuery([ "getUserRoles",
            { "access_token": access_token } ],
        GetUserRoles);

    const onToggle = isExpanded => setOpen(isExpanded);

    const onSelect = (event, selection, isPlaceholder) => {
        if (!isPlaceholder) {
            clearSelection()
            const role = data.filter(role => role.name === selection.toLowerCase())
            setSelected(role[0])
        }
        setOpen(false)
    }

    const clearSelection = () => {
        setOpen(false)
    };

    const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) => props.input.onChange([target.value])

    useEffect(() => {
        props.input.onChange(selected)
    }, [selected, props.input]);

    return(
        <FormGroup
            label={
                <Fragment>
                    Rôles de l'utilisateur&nbsp;
                    {isLoading && <Spinner size={"sm"}/> }
                </Fragment>}
            fieldId={props.input.name}
            validated={(props.meta.error && props.meta.touched) ? "error": "default"}
            autoComplete="off"
        >
            <Select
                name={props.input.name}
                onToggle={onToggle}
                onSelect={onSelect}
                onChange={handleChange}
                value={props.input.values}
                selections={selected?.name}
                isOpen={isOpen}
                aria-labelledby={titleId}
            >
                {data && data.map((role) =>
                <SelectOption key={role.id} value={role.name} />)}
            </Select>
        </FormGroup>
    )
};

export default SelectRoleOption;

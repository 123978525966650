import {createElement, createRef, FC, memo, startTransition, useCallback, useEffect, useState} from 'react';
import PlacesAutocomplete, {geocodeByAddress} from "react-places-autocomplete";
import styles from "./styles.module.scss";
import {FormGroup, List, ListItem, SearchInput, SearchInputProps, Text, TextContent} from "@patternfly/react-core";
import {GoLocation} from "react-icons/go";
import {FaExclamationCircle} from "react-icons/fa";

interface Props extends Omit<SearchInputProps,  'variant' | 'onBlur' > {
    placeholder?: string;
    isDisabled?: boolean;
    name: any;
    label?: string;
    isRequired?: boolean;
    onChange: (value: any) => void;
    onBlur?: (value: any) => void;
    error?: any;
    value?: any;
    arrayIndex?: number |undefined
    arrayPrefix?: string
    setValue?: any;
}

const SearchAddressInput: FC<Readonly<Props>> = memo((
    {
        placeholder,
        isDisabled,
        name,
        label,
        isRequired,
        onChange,
        onBlur,
        value,
        error,
        arrayIndex,
        arrayPrefix,
        setValue,
        ...rest
    }
) => {

    const [ q, setQ ] = useState<string>("");
    const ref = createRef<any>();
    const [address, setAddress] = useState<{
        text: string
        streetName?: string,
        houseNumber?: string,
        streetType?: string,
        apartmentNumber?: string,
        city?: string,
        postalCode?: string
    }>({
        text: "",
        streetName: "",
        houseNumber: "",
        streetType: "",
        apartmentNumber: "",
        city: "",
        postalCode: ""
    })

    const handleChange = useCallback((value) => {
        startTransition(() => {
            setQ(value)
        });
    }, []);

    const handleClear = useCallback(() => {
        setAddress({...address, text: ""});
    }, [address]);

    const handleSelect = async (value) => {
        const results = await geocodeByAddress(value);
        const addressComponents = results[0].address_components;

        const streetComponent = addressComponents.find(
            (component) => component.types.includes('route')
        );
        const houseNumberComponent = addressComponents.find(
            (component) => component.types.includes('street_number')
        );
        const apartmentNumberComponent = addressComponents.find(
            (component) => component.types.includes('subpremise')
        );
        const cityComponent = addressComponents.find(
            (component) => component.types.includes('locality') || component.types.includes('administrative_area_level_1')
        );
        const postalCodeComponent = addressComponents.find(
            (component) => component.types.includes('postal_code')
        );
        const provinceObj = addressComponents.find(
            (component) => component.types.includes('administrative_area_level_1')
        );

        const { long_name: streetName, short_name: streetType } = streetComponent || {};
        const { long_name: houseNumber } = houseNumberComponent || {};
        const { long_name: apartmentNumber } = apartmentNumberComponent || {};
        const { long_name: city } = cityComponent || {};
        const { long_name: postalCode } = postalCodeComponent || {};
        const { long_name: province } = provinceObj || {};

        setQ(value)

        const values = {
            text: value,
            streetName,
            houseNumber,
            streetType,
            apartmentNumber,
            city,
            postalCode,
            province
        };

        if (arrayIndex !== undefined) {
            setValue(`address.${arrayIndex}`, values);
        } else {
            setAddress(values);
            setValue(values);
        }
    };

    useEffect(() => {
        onChange!(address);
    }, [address])

    return (
        <PlacesAutocomplete
            value={q}
            onChange={handleChange}
            onSelect={handleSelect}
            debounce={200}
            highlightFirstSuggestion={true}
            shouldFetchSuggestions={true}
        >
            {({ getInputProps, suggestions, loading, getSuggestionItemProps }) => (
                <FormGroup
                    label={label}
                    isRequired={isRequired}
                    labelInfo={loading && `Chargement...`}
                    fieldId={label}
                    helperTextInvalid={error?.message}
                    helperTextInvalidIcon={<FaExclamationCircle />}
                    validated={error ? "error" : "success"}
                >
                    <SearchInput
                        onChange={(_event, value) => handleChange(value)}
                        onClear={handleClear}
                        onBlur={onBlur}
                        resultsCount={suggestions.length}
                        value={q}
                        isDisabled={isDisabled}
                        name={name}
                        ref={ref}
                        {...getInputProps({
                            placeholder: placeholder || "Rechercher un lieu",
                            className: styles.input,
                        })}
                        {...rest}
                    />
                    {suggestions.length > 0 && (
                        <List isPlain isBordered className={styles.options}>
                            {suggestions.map((suggestion, index) => {
                                const {description} = suggestion;
                                const className = suggestion.active
                                    ? "suggestion-item--active"
                                    : "suggestion-item";
                                return (
                                    <ListItem {...getSuggestionItemProps(suggestion, {className})} key={index}>
                                        <TextContent>
                                            <Text component="small">{createElement(GoLocation)}{' '}{description}</Text>
                                        </TextContent>
                                    </ListItem>
                                );
                            })}
                        </List>
                    )}
                </FormGroup>
            )}
        </PlacesAutocomplete>
    );
});

export default SearchAddressInput;
import React, {FC, SetStateAction, useEffect, useRef} from 'react';
import {
    Alert,
    AlertVariant,
    Button,
    Form,
    FormGroup,
    Grid,
    GridItem,
    InputGroup,
    TextInput
} from "@patternfly/react-core";
import _css from './styles.module.scss';
import {Field, Form as FinalForm} from "react-final-form";
import {EyeIcon} from '@patternfly/react-icons';
import {useMutation, useQueryClient} from "react-query";
import {UpdateUserPassword} from "api";

interface Props {
    defaultData: any
    toggleFetching: SetStateAction<any>
}

const ResetPasswordForm:FC<Props> = ({ defaultData, toggleFetching }) => {

    const passwordRef = useRef<any>();
    const confirmPasswordRef = useRef<any>();
    const request = useMutation(UpdateUserPassword);
    const queryClient = useQueryClient();
    const access_token = localStorage.getItem("x-access-token");

    const handleSubmit = async (data) => {
        const payload = {}
        payload["access_token"] = access_token
        payload["uid"] = defaultData.id
        payload["password"] = data["password"]
        await request.mutateAsync(payload);
        await queryClient.invalidateQueries("getUsers");
    }

    const required = value => (value ? undefined : value);

    const togglePasswordDisplay = (ref) => {
        switch (ref.current.type) {
            case "password":
                ref.current.type = "text"
                break
            case "text":
                ref.current.type = "password"
                break
        }
    }

    useEffect(() => {
        request && toggleFetching(request!.isLoading)
    }, [request.isLoading, toggleFetching])

    return (
        <FinalForm
            onSubmit={handleSubmit}
            subscription={{
                submitting: true,
            }}
            initialValues={{ employed: true }}
            validate={values => {
                const errors: any = {};
                if (!values.password) {
                    errors["password"] = "Champ obligatoire"
                }
                if (!values.confirm_password) {
                    errors["confirm_password"] = "Champ obligatoire"
                }
                if (values.confirm_password !== values.password) {
                    errors["confirm_password"] = "Les mots de passe ne correspondent pas"
                }
                return errors;
            }}
        >
            {({handleSubmit}) => (
                <Form onSubmit={handleSubmit} className={_css.container} id="update-user-password">
                    {request.isError &&
                    <Alert
                        isInline
                        variant={AlertVariant.danger}
                        title="Échec de mise à jour"
                    />}
                    {request.isSuccess &&
                    <Alert
                        isInline
                        variant={AlertVariant.success}
                        title="Mettre à jour!"
                    />}
                    <Grid hasGutter>
                        <GridItem lg={12}>
                            <Field
                                name="password"
                                validate={required}
                                subscription={{
                                    value: true,
                                    active: true,
                                    error: true,
                                    touched: true
                                }}
                            >
                                {({ input, meta }) => (
                                    <FormGroup
                                        label="Mot de passe"
                                        isRequired
                                        fieldId={input.name}
                                        helperTextInvalid={meta.error && meta.touched && meta.error}
                                        validated={(meta.error && meta.touched) ? "error": "default"}
                                        autoComplete="off"
                                    >
                                        <InputGroup>
                                            <TextInput
                                                validated={(meta.error && meta.touched) ? "error": "default"}
                                                isRequired
                                                type="password"
                                                id="password"
                                                placeholder="Mot de passe"
                                                onBlur={input.onBlur}
                                                onChange={input.onChange}
                                                name={input.name}
                                                ref={passwordRef}
                                            />
                                            <Button
                                                variant="control"
                                                aria-label="search button for search input"
                                                onClick={() => togglePasswordDisplay(passwordRef)}
                                            >
                                                <EyeIcon />
                                            </Button>
                                        </InputGroup>
                                    </FormGroup>
                                )}
                            </Field>
                        </GridItem>
                        <GridItem lg={12}>
                            <Field
                                name="confirm_password"
                                validate={required}
                                subscription={{
                                    value: true,
                                    active: true,
                                    error: true,
                                    touched: true
                                }}
                            >
                                {({ input, meta }) => (
                                    <FormGroup
                                        label="Confirmez le mot de passe"
                                        isRequired
                                        fieldId={input.name}
                                        helperTextInvalid={meta.error && meta.touched && meta.error}
                                        validated={(meta.error && meta.touched) ? "error": "default"}
                                        autoComplete="off"
                                    >
                                        <InputGroup>
                                            <TextInput
                                                validated={(meta.error && meta.touched) ? "error": "default"}
                                                isRequired
                                                type="password"
                                                id="confirm_password"
                                                placeholder="Confirmez le mot de passe"
                                                onBlur={input.onBlur}
                                                onChange={input.onChange}
                                                name={input.name}
                                                ref={confirmPasswordRef}
                                            />
                                            <Button
                                                variant="control"
                                                aria-label="search button for search input"
                                                onClick={() => togglePasswordDisplay(confirmPasswordRef)}
                                            >
                                                <EyeIcon />
                                            </Button>
                                        </InputGroup>
                                    </FormGroup>
                                )}
                            </Field>
                        </GridItem>
                    </Grid>
                </Form>
            )}
        </FinalForm>
    );
};

export default ResetPasswordForm;

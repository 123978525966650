import React, {useContext} from "react";
import _css from "./CardHeader.module.scss"
import {Bullseye, List, ListItem, ListVariant} from "@patternfly/react-core";
import {push} from 'react-router-redirect';
import {useLocation} from "react-router-dom";
import {SessionsContext} from "contexts";
import {ChevronDownIcon} from "@patternfly/react-icons";

interface Props {
    backgroundColor?: string
    color?: string
}

const CardHeader:React.FC<Props> = (props) => {

    const {backgroundColor, color} = props;
    const location = useLocation();
    const {sess} = useContext(SessionsContext);

    return (
        <React.Fragment>
            <header
                className={_css.container}
                style={{backgroundColor: backgroundColor, color: color}}
            >
                <Bullseye>
                    <List variant={ListVariant.inline}>
                        <ListItem
                            className={(location && location.pathname === "/") ? _css.active_link: ""}
                            onClick={() => push("/")}>
                            <Bullseye>Accueil</Bullseye>
                        </ListItem>
                        <ListItem
                            className={(location && location.pathname === "/offers") ? _css.active_link: ""}
                            onClick={() => push("/offers")}>
                            <Bullseye>Les Offres</Bullseye>
                        </ListItem>
                        <ListItem
                            onClick={() => push("/blogue.chronics")}
                            className={(location && location.pathname === "/blogue.chronics") ? _css.active_link: ""}
                        >
                            <Bullseye>Communiqués</Bullseye>
                        </ListItem>
                        <ListItem
                            className={(location && location.pathname === "/pitons.bank") ? _css.active_link: ""}
                            onClick={() => push("/pitons.bank")}>
                            <Bullseye>Banque à pitons</Bullseye>
                        </ListItem>
                        <ListItem
                            onClick={() => push("/joinus")}
                            className={(location && location.pathname === "/joinus") ? _css.active_link: ""}
                        >
                            <Bullseye>Nous Joindre</Bullseye>
                        </ListItem>
                        {sess &&
                        <ListItem
                            onClick={() => push("/space")}
                            className={_css.my_space_button}>
                            <Bullseye>Mon espace&nbsp;&nbsp;
                                <ChevronDownIcon style={{
                                    marginTop : 4
                                }}/>
                            </Bullseye>
                        </ListItem>}
                    </List>
                </Bullseye>
            </header>
        </React.Fragment>
    )
}

CardHeader.defaultProps = {
    backgroundColor: "#76bd1d",
    color: "#ffffff"
}

export {CardHeader};

import React, {FC, SelectHTMLAttributes, useEffect, useState} from "react";
import {FormGroup, Select, SelectDirection, SelectOption, SelectVariant} from "@patternfly/react-core";
import {ExclamationCircleIcon} from "@patternfly/react-icons";
import _css from "./styles.module.scss";

interface Props extends SelectHTMLAttributes<HTMLSelectElement> {
    dataOptions: any;
}

const SelectField:FC<Props> = React.memo((props) => {

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [selected, setSelected] = useState<any>();
    const [options, setOptions] = useState<any>([]);

    const createState = (id, name) => {
        return {
            id: id,
            name: name,
            toString: function() {
                return `${this.name}`;
            },
        };
    };

    const onToggle = () => setIsOpen(!isOpen);

    const onSelect = (event, selection, isPlaceholder) => {
        if (isPlaceholder) {
            clearSelection()
        } else {
            props.onChange!(selection.id)
        }
        setSelected(selection.name);
        setIsOpen(false);
    };

    const clearSelection = () => {
        setSelected(null);
        setIsOpen(false);
    };

    const organizeData = (data: any) => {
        setOptions( [
            <SelectOption
                key={0}
                value={props.placeholder}
                isPlaceholder
                isDisabled
                className={_css.placeholder}
            />,
            data.map((lt) => <SelectOption key={lt.id} value={createState(lt.id, lt.title)} />)
        ]);
    };

    useEffect(() => {
        organizeData(props.dataOptions);
        // eslint-disable-next-line
    }, [props.dataOptions]);


    return (
        <FormGroup
            label="Type"
            fieldId={"contract_date"}
            helperTextInvalidIcon={<ExclamationCircleIcon/>}
            autoComplete="off"
            isRequired
        >
            <Select
                variant={SelectVariant.single}
                aria-label="Select Input"
                onToggle={onToggle}
                onSelect={onSelect}
                selections={selected}
                isOpen={isOpen}
                required
                aria-labelledby="select loan types"
                direction={SelectDirection.down}
                className={_css.container}
            >
                {options}
            </Select>
        </FormGroup>
    )
})

export default SelectField;

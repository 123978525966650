import React, {Fragment, useEffect, useRef, useState} from "react";
import {Field, Form as FinalForm} from "react-final-form";
import MaskedInput from 'react-text-mask'; // Import MaskedInput
import arrayMutators from "final-form-arrays";
import _css from "./UpdateProfileContactForm.module.scss";
import {
    Alert,
    AlertActionCloseButton,
    Button,
    Grid,
    GridItem,
    Skeleton,
    Spinner,
    Text,
    Title
} from "@patternfly/react-core";
import variants from "sass/colors.module.scss";
import {DECODE_SESSION_TOKEN, UPDATE_SESSION_ACCOUNT_CONTACT} from "services/urls";
import {InputErrors} from "components";
import useRequestFetch from "react-requests-fetch";

type Message = {
    variant: "success" | "danger" | "default" | "warning" | "info" | undefined
    message: string
} | undefined

const UpdateProfileContactForm: React.FC = () => {

    const accessToken = localStorage.getItem("x-imperso") || localStorage.getItem('x-access-token')
    const [ fetching, setFetching ] = useState<boolean>(true);
    const [ message, setMessage ] = useState<Message>();
    const [ account, setAccount ] = useState<any>();
    const [ displayPhoneIsChecked, setdisplayPhoneIsChecked ] = useState(false)
    const [ displayEmailIsChecked, setdisplayEmailIsChecked ] = useState(false)

    const formRef = useRef<any>();

    const [ reqAccount, getReqAccount ] = useRequestFetch({
        uri : DECODE_SESSION_TOKEN,
        headers : { "x-access-token" : String(accessToken) }
    });

    useEffect(() => {
        setdisplayPhoneIsChecked(reqAccount?.payload?.is_phone_visible);
        setdisplayEmailIsChecked(reqAccount?.payload?.is_email_visible);
    }, [
        reqAccount?.payload?.is_phone_visible,
        reqAccount?.payload?.is_email_visible
    ])

    const handleSubmit = (values) => {
        setFetching(true)
        // console.log(displayPhoneIsChecked)
        values.display_phone_mobile = displayPhoneIsChecked
        values.display_email = displayEmailIsChecked
        console.log(values)

        fetch(UPDATE_SESSION_ACCOUNT_CONTACT, {
            method : "PUT",
            headers : {
                "x-access-token" : String(accessToken),
                "Content-Type" : "application/json",
            },
            body : JSON.stringify(values)
        })
            .then((response) => response.status)
            .then(async (data) => {
                data === 201 ?
                    setMessage({
                        "variant" : "success",
                        "message" : "Vos coordonnées ont été mises à jour !"
                    }) :
                    setMessage({
                        "variant" : "danger",
                        "message" : "Oops, un erreur de serveur provoque cette erreur, réessayez et si cela persiste, veuillez contacter le support technique !"
                    })
            }).finally(() => {
            getReqAccount({
                uri : DECODE_SESSION_TOKEN,
                headers : { "x-access-token" : String(accessToken) }
            })
            setFetching(false)
        })
    }

    useEffect(() => {
        if (reqAccount && reqAccount.statusCode === 200) {
            setAccount(reqAccount.payload)
            setFetching(false)
        }
        // eslint-disable-next-line
    }, [ reqAccount, account ])

    const getValidator = isRequired =>
    isRequired ? value => (value ? undefined : "Required") : () => {};

    return (
        <Fragment>
            <FinalForm
                onSubmit={handleSubmit}
                subscription={{
                    submitting : true,
                }}
                initialValues={{
                    phone_work : (account && account.extra_information) ? account.extra_information.phone_work : "",
                    phone_mobile : (account && account.extra_information) ? account.extra_information.phone_mobile : "",
                    phone_residence : (account && account.extra_information) ? account.extra_information.phone_residence : "",
                    display_phone_mobile: reqAccount?.payload?.is_phone_visible,
                    display_email: reqAccount?.payload?.is_email_visible
                }}
                mutators={{
                    ...arrayMutators
                }}
            >
                {({
                      handleSubmit,
                  }) => {

                    return (
                        <form
                            onSubmit={handleSubmit}
                            className={_css.container}
                            ref={formRef}
                        >
                            <Grid hasGutter>
                                <GridItem>
                                    <Title headingLevel={"h3"} size="3xl">
                                        Préférences de contact
                                    </Title>
                                    <GridItem span={5} rowSpan={2}>
                                        <Text>
                                            Vos informations de contact préférées pour votre compte de profil.
                                        </Text>
                                    </GridItem>
                                </GridItem>
                                <GridItem span={6}>
                                    {fetching && <Skeleton height="50px" screenreaderText="Loading contents"/>}
                                    {!fetching && <Field
                                        name="phone_mobile"
                                        subscription={{
                                            value : true,
                                            active : true,
                                            error : true,
                                            touched : true
                                        }}
                                    >
                                        {({
                                              input,
                                              meta
                                          }) => (
                                            <MaskedInput
                                            {...input}
                                            mask={[
                                                '(',
                                                /[1-9]/,
                                                /\d/,
                                                /\d/,
                                                ')',
                                                ' ',
                                                /\d/,
                                                /\d/,
                                                /\d/,
                                                '-',
                                                /\d/,
                                                /\d/,
                                                /\d/,
                                                /\d/
                                            ]}
                                            placeholder={"Numéro de téléphone portable"}
                                            id="phone_mobile"
                                            type="text"
                                            guide={false} // Prevents placeholder characters from being displayed
                                            onBlur={input.onBlur}
                                            name={input.name}
                                            defaultValue={(account && account.extra_information) ? account.extra_information.phone_mobile : ""}
                                            className={[
                                                _css.input,
                                                (meta.error && meta.touched) && variants.error_input
                                            ].join(" ")}

                                            {...(meta.error && meta.touched) &&
                                                            <InputErrors message={meta.error} />}/>
                                            // <label htmlFor="phone_mobile">
                                            //     <span className={_css.labels}>Numéro de téléphone portable</span>
                                            //     <input
                                            //         className={[
                                            //             _css.input,
                                            //             (meta.error && meta.touched) && variants.error_input
                                            //         ].join(" ")}
                                            //         type="text"
                                            //         id="phone_mobile"
                                            //         onBlur={input.onBlur}
                                            //         onChange={input.onChange}
                                            //         name={input.name}
                                            //         placeholder={"Numéro de téléphone portable"}
                                            //         defaultValue={(account && account.extra_information) ? account.extra_information.phone_mobile : ""}
                                            //     />
                                            //     {(meta.error && meta.touched) && <InputErrors message={meta.error}/>}
                                            // </label>
                                        )}
                                    </Field>}
                                    <Field
                                        name="display_phone_mobile"
                                        type="checkbox"
                                        defaultValue={reqAccount?.payload?.is_phone_visible}
                                    >
                                    {({ input, value })=> (
                                        <label htmlFor="display_phone_mobile">
                                            <span className={_css.labels}> Visible par tous les membres</span>{" "}
                                            <input
                                                type="checkbox"
                                                name={input.name}
                                                id="display_phone_mobile"
                                                checked={displayPhoneIsChecked}
                                                onChange={(e) => setdisplayPhoneIsChecked(e.target.checked)}
                                                value={value}
                                                defaultChecked={reqAccount?.payload?.is_phone_visible}
                                            />
                                        </label>
                                    )}
                                    </Field>
                                </GridItem>
                                <GridItem span={6}>
                                    {/* <Text>
                                        Rendre visible
                                    </Text> */}
                                </GridItem>
                                {/* <GridItem span={5} rowSpan={2}>
                                    <Field
                                        name="display_phone_number"
                                        component="input"
                                        type="checkbox"
                                        value="true"
                                        // className="custom-control-input"
                                        id="male"
                                        />
                                    <label htmlFor="display_phone">
                                        <span className={_css.labels}> Visible pour tous </span>

                                    </label>
                                </GridItem> */}
                                <GridItem lg={6}>
                                    {fetching && <Skeleton height="50px" screenreaderText="Loading contents"/>}
                                    {!fetching && <Field
                                        name="phone_work"
                                        subscription={{
                                            value : true,
                                            active : true,
                                            error : true,
                                            touched : true
                                        }}
                                    >
                                        {({
                                              input,
                                              meta
                                          }) => (
                                            <label htmlFor="phone_work">
                                                <span className={_css.labels}>Téléphone de travail</span>
                                                <input
                                                    className={[
                                                        _css.input,
                                                        (meta.error && meta.touched) && variants.error_input
                                                    ].join(" ")}
                                                    type="text"
                                                    id="phone_work"
                                                    onBlur={input.onBlur}
                                                    onChange={input.onChange}
                                                    name={input.name}
                                                    placeholder={"Téléphone de travail"}
                                                    defaultValue={(account && account.extra_information) ? account.extra_information.phone_work : ""}
                                                />
                                                {(meta.error && meta.touched) && <InputErrors message={meta.error}/>}
                                            </label>
                                        )}
                                    </Field>}
                                </GridItem>
                                <GridItem span={5}>
                                    {/* <Text>
                                        Rendre visible
                                    </Text> */}
                                </GridItem>
                                <GridItem lg={6}>
                                    {fetching && <Skeleton height="50px" screenreaderText="Loading contents"/>}
                                    {!fetching && <Field
                                        name="email"
                                        subscription={{
                                            value : true,
                                            active : true,
                                            error : true,
                                            touched : true
                                        }}
                                    >
                                        {({
                                              input,
                                              meta
                                          }) => (
                                            <label htmlFor="email">
                                                <span className={_css.labels}>Adresse courriel</span>
                                                <input
                                                    className={[
                                                        _css.input,
                                                        (meta.error && meta.touched) && variants.error_input
                                                    ].join(" ")}
                                                    type="text"
                                                    id="email"
                                                    onBlur={input.onBlur}
                                                    onChange={input.onChange}
                                                    name={input.name}
                                                    placeholder={"Adresse courriel"}
                                                    defaultValue={(account ) ? account.email : ""}
                                                />
                                                {(meta.error && meta.touched) && <InputErrors message={meta.error}/>}
                                            </label>
                                        )}
                                    </Field>}
                                    <Field
                                        name="display_email"
                                        type="checkbox"
                                    >
                                    {({ input,
                                        value,
                                    })=> (
                                        <label htmlFor="display_email">
                                            <span className={_css.labels}> Visible par tous les membres </span>
                                            <input

                                                type="checkbox"
                                                name={input.name}
                                                id="display_email"
                                                checked={displayEmailIsChecked}
                                                onChange={(e) => setdisplayEmailIsChecked(e.target.checked)}
                                                defaultChecked = {reqAccount?.payload?.is_email_visible}
                                                value={value}
                                            />
                                        </label>
                                    )}
                                    </Field>
                                </GridItem>
                                <GridItem span={5}></GridItem>
                                <GridItem lg={6}>
                                    {fetching && <Skeleton height="50px" screenreaderText="Loading contents"/>}
                                    {!fetching && <Field
                                        name="phone_residence"
                                        subscription={{
                                            value : true,
                                            active : true,
                                            error : true,
                                            touched : true
                                        }}
                                    >
                                        {({
                                              input,
                                              meta
                                          }) => (
                                            <label htmlFor="phone_residence">
                                                <span className={_css.labels}>Numéro de téléphone de la résidence</span>
                                                <input
                                                    className={[
                                                        _css.input,
                                                        (meta.error && meta.touched) && variants.error_input
                                                    ].join(" ")}
                                                    type="text"
                                                    id="phone_residence"
                                                    onBlur={input.onBlur}
                                                    onChange={input.onChange}
                                                    name={input.name}
                                                    placeholder={"Numéro de téléphone de la résidence"}
                                                    defaultValue={(account && account.extra_information) ? account.extra_information.phone_residence : ""}
                                                />
                                                {(meta.error && meta.touched) && <InputErrors message={meta.error}/>}
                                            </label>
                                        )}
                                    </Field>}
                                </GridItem>
                                {(!fetching && message) &&
                                <GridItem>
                                    <Alert isInline variant={message.variant || "default"}
                                           title={"Mise à jour du compte"}
                                           actionClose={<AlertActionCloseButton
                                               onClose={() => setMessage(undefined)}/>}>
                                        <Text>
                                            {message.message}
                                        </Text>
                                    </Alert>
                                </GridItem>}
                                <GridItem>
                                    <Button isDisabled={fetching} type={"submit"}>
                                        {fetching ? <Spinner size="sm"/> : <span>Mettre à jour</span>}
                                    </Button>
                                </GridItem>
                            </Grid>
                        </form>
                    )
                }}
            </FinalForm>
        </Fragment>
    )
}

export default UpdateProfileContactForm;

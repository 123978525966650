import React, {createContext, FC, useMemo, useState} from "react";

export const SideBarNavContext = createContext<any>(null);

interface Props {
    children: any
}

type properties = {
    active: boolean,
}|null

export const ContextSideBarNav:FC<Props> = ({ children }) => {

    const [navData, setNavData] = useState<properties>({
        active: false,
    });

    const providerNavValue = useMemo(() => ({navData, setNavData}), [navData, setNavData]);

    return(
        <React.Fragment>
            <SideBarNavContext.Provider value={providerNavValue}>
                {children}
            </SideBarNavContext.Provider>
        </React.Fragment>
    )
}

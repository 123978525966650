import React, {useState} from "react";
import {Field, Form as FinalForm} from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import _css from "./JoinUs.module.scss";
import variants from "sass/colors.module.scss";
import {Grid, GridItem, Select, SelectDirection, SelectOption, SelectVariant} from "@patternfly/react-core";
import ReCAPTCHA from "react-google-recaptcha";
import {Editor} from '@tinymce/tinymce-react';

interface Props {}

const JoinUsForm:React.FC<Props> = () => {

    const required = value => (value ? undefined: 'sign-up-form-label.field-required');
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [selected, setSelected] = useState<any>(null);
    const recaptchaRef = React.createRef<any>();

    const onSubmit = (values) => {
        // const recaptchaValue = recaptchaRef.current.getValue();
    }

    const options = [
        { value: 'Choisir...', disabled: true, isPlaceholder: true },
        { value: 'Sujet autres', disabled: false },
        { value: 'Commentaires', disabled: false },
        { value: 'Questions', disabled: false }
    ];

    const onToggle = isOpen => {
        setIsOpen(isOpen)
    };

    const onSelect = (event, selection, isPlaceholder) => {
        if (isPlaceholder) {
            clearSelection()
        } else {
            setSelected(selection)
            setIsOpen(false)
        }
    };

    const clearSelection = () => {
        setSelected(null);
        setIsOpen(false)
    };

    const onChange = (event) => {

    }

    const handleEditorChange = (e) => {
        // setMessage(e.target.getContent())
    }

    return (
        <React.Fragment>
            <FinalForm
                onSubmit={onSubmit}
                subscription={{
                    submitting: true,
                }}
                mutators={{
                    // potentially other mutators could be merged here
                    ...arrayMutators
                }}
                validate={values => {
                    const errors = {};
                    if (!values.name) {
                        errors["name"] = "Required";
                    }
                    if (!values.email) {
                        errors["email"] = "Required";
                    }
                    return errors;
                }}
            >
                {({handleSubmit}) => (
                    <form onSubmit={handleSubmit} className={_css.container}>
                        <Grid hasGutter>
                            <GridItem>
                                <Grid hasGutter className={_css.form_components}>
                                    <GridItem lg={12}>
                                        <Field
                                            name="email"
                                            validate={required}
                                            subscription={{
                                                value: true,
                                                active: true,
                                                error: true,
                                                touched: true
                                            }}
                                        >
                                            {({ input, meta }) => (
                                                <label htmlFor="user_id">
                                                    <input
                                                        className={[
                                                            _css.input,
                                                            (meta.error && meta.touched) && variants.error_input
                                                        ].join(" ")}
                                                        type="text"
                                                        id="email"
                                                        onBlur={input.onBlur}
                                                        onChange={input.onChange}
                                                        name={input.name}
                                                        placeholder={"Votre adresse courriel"}
                                                    />
                                                </label>
                                            )}
                                        </Field>
                                    </GridItem>
                                    <GridItem lg={12}>
                                        <Field
                                            name="name"
                                            validate={required}
                                            subscription={{
                                                value: true,
                                                active: true,
                                                error: true,
                                                touched: true
                                            }}
                                        >
                                            {({ input, meta }) => (
                                                <label htmlFor="name">
                                                    <input
                                                        className={[
                                                            _css.input,
                                                            (meta.error && meta.touched) && variants.error_input
                                                        ].join(" ")}
                                                        type="name"
                                                        id="name"
                                                        onBlur={input.onBlur}
                                                        onChange={input.onChange}
                                                        name={input.name}
                                                        placeholder={"Votre nom"}
                                                    />
                                                </label>
                                            )}
                                        </Field>
                                    </GridItem>
                                    <GridItem lg={2}>
                                        <Select
                                            variant={SelectVariant.single}
                                            aria-label="Select Input"
                                            onToggle={onToggle}
                                            onSelect={onSelect}
                                            selections={selected}
                                            isOpen={isOpen}
                                            aria-labelledby={"selectioner l'option"}
                                            direction={SelectDirection.down}
                                        >
                                            {options.map((option, index) => (
                                                <SelectOption
                                                    isDisabled={option.disabled}
                                                    key={index}
                                                    value={option.value}
                                                    isPlaceholder={option.isPlaceholder}
                                                />
                                            ))}
                                        </Select>
                                    </GridItem>
                                    <GridItem lg={12}>
                                        <Editor
                                            apiKey="osv8722vdzi6swvc8g08x9zwpcn8ylp9nck7qbr2ti8iail5"
                                            init={{
                                                plugins: [
                                                    'searchreplace visualblocks code',
                                                    'insertdatetime paste wordcount'
                                                ],
                                                toolbar:
                                                    'undo redo | formatselect | bold italic |alignleft aligncenter alignright | bullist numlist outdent indent | help'
                                            }}
                                            onChange={handleEditorChange}
                                        />
                                    </GridItem>
                                    <GridItem lg={12}>
                                        <ReCAPTCHA
                                            ref={recaptchaRef}
                                            sitekey="6LfjpscZAAAAAD8afX2xBQdbh_6ney9MuefCxcp_"
                                            onChange={onChange}
                                        />
                                    </GridItem>
                                    <GridItem>
                                        <button type={"submit"}>Envoyer</button>
                                    </GridItem>
                                </Grid>
                            </GridItem>
                        </Grid>
                    </form>
                )}
            </FinalForm>
        </React.Fragment>
    )
}

export {JoinUsForm};

import React, {createContext, useMemo, useState} from "react";

interface Props {
    children: any;
}

export const SessionsContext = createContext<any>(null);

export const ContextSessions:React.FC<Props> = ({ children }) => {

    const xst = localStorage.getItem("x-imperso") || localStorage.getItem("x-access-token")
    const [sess, setSess] = useState<string|null>(xst && xst);

    const providerValue = useMemo(() => ({sess, setSess}), [sess, setSess]);

    return (
        <React.Fragment>
            <SessionsContext.Provider value={providerValue}>
                {children}
            </SessionsContext.Provider>
        </React.Fragment>
    )
}

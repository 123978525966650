import React from 'react';
import _css from "./Overlays.module.scss";

interface Props {
    color: string
    image: string
}

export const BackgroundOverlays:React.FC<Props> = (props) => {

    const {
        color,
        image
    } = props;

    return (
        <div className={_css.container}>
            <div
                className={_css.cover}
                style={{
                    backgroundColor : color
                }}
            />
            <picture>
                <img
                    sizes="(max-width: 1400px) 100vw, 1400px"
                    srcSet={`
                    ${image},w_200.png 1x,
                    ${image},w_362.png 1x,
                    ${image},w_475.png 2x,
                    ${image},w_569.png 2x,
                    ${image},w_659.png 2x,
                    ${image},w_733.png 3x,
                    ${image},w_802.png 3x,
                    ${image},w_867.png 3x,
                    ${image},w_928.png 3x,
                    ${image},w_987.png 3x,
                    ${image},w_1041.png 4x,
                    ${image},w_1094.png 4x,
                    ${image},w_1147.png 4x,
                    ${image},w_1197.png 4x,
                    ${image},w_1246.png 4x,
                    ${image},w_1294.png 4x,
                    ${image},w_1341.png 4x,
                    ${image},w_1387.png 4x,
                    ${image},w_1396.png 4x,
                    ${image},w_1400.png 4x,"`}
                    src={`${image},w_1400.png 4x,"`}
                    alt="cover_background"/>
            </picture>

        </div>
    )
}


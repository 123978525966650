import React, {lazy} from 'react';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {Puff} from 'react-loader-spinner';
import {Redirector} from 'react-router-redirect';
import {SideNav} from "components";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const Home = lazy(() =>
    import('pages').then(({ Home }) => ({ default : Home })));
const Offers = lazy(() =>
    import('pages').then(({ Offers }) => ({ default : Offers })));
const PitonsBank = lazy(() =>
    import('pages').then(({ PitonsBank }) => ({ default : PitonsBank })));
const UserRegister = lazy(() =>
    import('pages').then(({ UserRegister }) => ({ default : UserRegister })));
const JoinUs = lazy(() =>
    import('pages').then(({ JoinUs }) => ({ default: JoinUs })));
const SignUp = lazy(() =>
    import('pages').then(({ SignUp }) => ({ default: SignUp })));
const RecoverPassword = lazy(() =>
    import('pages').then(({ RecoverPassword }) => ({ default: RecoverPassword })));
const BlogueChronic = lazy(() =>
    import('pages').then(({ BlogueChronic }) => ({ default : BlogueChronic })));
const MySpaceHome = lazy(() =>
    import('pages').then(({ MySpaceHome }) => ({ default : MySpaceHome })));
const RegistrationAppointment = lazy(() =>
    import('pages').then(({ RegistrationAppointment }) => ({ default : RegistrationAppointment })));
const NotFound = lazy(() =>
    import('pages').then(({ NotFound }) => ({ default : NotFound })));
const ResetPassword = lazy(() =>
    import('pages').then(({ ResetPassword }) => ({ default : ResetPassword })));

const RecoverPublicPassword = lazy(() =>
    import('pages').then(({ RecoverPublicPassword }) => ({ default : RecoverPublicPassword })));

function App() {
    return (
        <React.Fragment>
            <Router>
                <Redirector />
                <SideNav />
                <React.Suspense fallback={
                    <div style={{
                        textAlign: "center",
                        width: "100%",
                        marginTop: "8%"
                    }}>
                        <Puff
                            color="#00aebc"
                            height={100}
                            width={100}
                        />
                    </div>}>
                    <Switch>
                        <Route
                            path={'/'}
                            exact
                            render={renderProps => <Home {...renderProps} />}
                        />
                        <Route
                            path={'/offers'}
                            render={renderProps => <Offers {...renderProps} />}
                        />
                        <Route
                            path={'/pitons.bank'}
                            render={renderProps => <PitonsBank {...renderProps} />}
                        />
                        <Route
                            path={'/blogue.chronics'}
                            render={renderProps => <BlogueChronic {...renderProps} />}
                        />
                        <Route
                            path={'/register/user'}
                            render={renderProps => <UserRegister {...renderProps} />}
                        />
                        <Route
                            path={'/joinus'}
                            render={renderProps => <JoinUs {...renderProps} />}
                        />
                        <Route
                            path={'/sign_up'}
                            exact
                            render={renderProps => <SignUp {...renderProps} />}
                        />
                        <Route
                            path={'/sign_up/appointment/:token'}
                            render={renderProps => <RegistrationAppointment {...renderProps} />}
                        />
                        <Route
                            path={'/password.recovery'}
                            render={renderProps => <RecoverPassword {...renderProps} />}
                        />
                        <Route
                            path={'/password/recovery/:token'}
                            render={renderProps => <RecoverPublicPassword {...renderProps} />}
                        />
                        <Route
                            path={'/space'}
                            render={renderProps => <MySpaceHome {...renderProps} />}
                        />

                        <Route render={renderProps => <NotFound {...renderProps} />}/>
                    </Switch>
                </React.Suspense>
            </Router>
        </React.Fragment>
    );
}

export default App;

import { off } from "process";
import {BASE} from "../services/urls";


export const API_SEARCH_OFFERS = async ({queryKey}): Promise<ReturnType<() => {
    count: number
    offers: { [key: string]: any }[]
}>> => {

    const token = localStorage.getItem("x-imperso") || localStorage.getItem('x-access-token')
    const { limit, offset, q } = queryKey[1];

    const options: any = {
        method: 'GET',
        headers: {
            'accept': 'application/json',
            'lang': localStorage.getItem('i18nextLng'),
            'x-access-token': token,
        }
    };

    const response = await fetch(`${BASE}/offers/search?param=${q}&limit=${limit}&page=${offset}`, options);
    const result = response.json();
    if (!response.ok) throw new Error(JSON.stringify(response.text()))
    return result
}

export const API_GET_OFFERS = async ({queryKey}): Promise<ReturnType<() => {
    count: number
    offers: { [key: string]: any }[]
}>> => {


    const { limit, offset } = queryKey[1];
    const token = localStorage.getItem("x-imperso") || localStorage.getItem('x-access-token')

    const options: any = {
        method: 'GET',
        headers: {
            'accept': 'application/json',
            'x-access-token': token,
        }
    };
    const response = await fetch(`${BASE}/offers/?limit=${limit}&page=${offset}`, options);
    const result = response.json();
    if (!response.ok) throw new Error(JSON.stringify(response.text()))
    return result
}

export const API_GET_OFFER = async (data: {id: number, limit: number, offset: number}): Promise<any> => {

    const { id, limit, offset } = data;
    const token = localStorage.getItem("x-imperso") || localStorage.getItem('x-access-token')

    const options: any = {
        method: 'GET',
        headers: {
            'accept': 'application/json',
            'x-access-token': token,
        }
    };
    const response = await fetch(`${BASE}/offers/?id=${id}&limit=${limit}&page=${offset}`, options as any);
    const result = response.json();
    if (!response.ok) throw new Error(JSON.stringify(response.text()))
    return result
}
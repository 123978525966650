import React from 'react';
import {createRoot} from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {ContextSessions, ContextSideBarNav} from "contexts";
import 'moment/locale/fr';
import {QueryClient, QueryClientProvider} from 'react-query';

const queryClient = new QueryClient();

const root = createRoot(
    document.getElementById('root') as HTMLElement
);


root.render(
    <React.Suspense fallback={<span>loading...</span>}>
        <ContextSessions>
            <ContextSideBarNav>
                <QueryClientProvider client={queryClient}>
                    <App />
                </QueryClientProvider>
            </ContextSideBarNav>
        </ContextSessions>
    </React.Suspense>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import React from "react";
import {Field, Form as FinalForm} from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import _css from "./Offers.module.scss";
import variants from "sass/colors.module.scss";
import {Grid, GridItem, List, ListItem, ListVariant} from "@patternfly/react-core";

interface Props {}

export const OffersForm:React.FC<Props> = () => {


    const required = value => (value ? undefined: 'sign-up-form-label.field-required');

    const onSubmit = (values) => {
    }

    return (
        <React.Fragment>
            <FinalForm
                onSubmit={onSubmit}
                subscription={{
                    submitting: true,
                }}
                mutators={{
                    // potentially other mutators could be merged here
                    ...arrayMutators
                }}
                validate={values => {
                    const errors = {};
                    if (!values.password) {
                        errors["password"] = "Required";
                    }
                    if (!values.confirm) {
                        errors["confirm"] = "Required";
                    } else if (values["confirm-password"] !== values.password) {
                        errors["confirm"] = "must-match-password-field";
                    }
                    return errors;
                }}
            >
                {({handleSubmit}) => (
                    <form onSubmit={handleSubmit} className={_css.container}>
                        <Grid hasGutter>
                            <GridItem>
                                <Field
                                    name="description"
                                    validate={required}
                                    subscription={{
                                        value: true,
                                        active: true,
                                        error: true,
                                        touched: true
                                    }}
                                >
                                    {({ input, meta }) => (
                                        <label htmlFor="description">
                                            <input
                                                className={[
                                                    _css.input,
                                                    (meta.error && meta.touched) && variants.error_input
                                                ].join(" ")}
                                                type="text"
                                                id="description"
                                                onBlur={input.onBlur}
                                                onChange={input.onChange}
                                                name={input.name}
                                                placeholder={"Description Courte:"}
                                            />
                                        </label>
                                    )}
                                </Field>
                                <p>Accompagnatrice avec voiture</p>
                            </GridItem>
                            <GridItem>
                                <Field
                                    name="description_long"
                                    validate={required}
                                    subscription={{
                                        value: true,
                                        active: true,
                                        error: true,
                                        touched: true
                                    }}
                                >
                                    {({ input, meta }) => (
                                        <label htmlFor="description_long">
                                            <textarea
                                                className={[
                                                    _css.textarea,
                                                    (meta.error && meta.touched) && variants.error_input
                                                ].join(" ")}
                                                id="description_long"
                                                onBlur={input.onBlur}
                                                onChange={input.onChange}
                                                name={input.name}
                                                placeholder={"Description Longue"}
                                            />
                                        </label>
                                    )}
                                </Field>
                                <p>Seul les membres inscrits peuvent voir les détails de l'offre.</p>
                            </GridItem>
                            <GridItem>
                                <Field
                                    name="category"
                                    validate={required}
                                    subscription={{
                                        value: true,
                                        active: true,
                                        error: true,
                                        touched: true
                                    }}
                                >
                                    {({ input, meta }) => (
                                        <label htmlFor="category">
                                            <input
                                                className={[
                                                    _css.input,
                                                    (meta.error && meta.touched) && variants.error_input
                                                ].join(" ")}
                                                type="text"
                                                id="category"
                                                onBlur={input.onBlur}
                                                onChange={input.onChange}
                                                name={input.name}
                                                placeholder={"Catégorie"}
                                            />
                                        </label>
                                    )}
                                </Field>
                                <p>Accompagnement & Gardiennage</p>
                            </GridItem>
                            <GridItem>
                                <List variant={ListVariant.inline}>
                                    <ListItem>
                                        <button className={_css.btn_communicate}>Communiquer</button>
                                    </ListItem>
                                    <ListItem>
                                        <button className={_css.btn_close}>Fermer</button>
                                    </ListItem>
                                </List>
                            </GridItem>
                        </Grid>
                    </form>
                )}
            </FinalForm>
        </React.Fragment>
    )
}

import React from 'react';
import _css from './InputErrors.module.scss';
import {Text} from "@patternfly/react-core";

interface Props {
    message: string
}

export const InputErrors: React.FC<Props> = ({
                                                 message
                                             }) => {


    return (
        <div className={_css.container}>
            <Text>{message}</Text>
        </div>
    )
}

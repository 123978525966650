import React from "react";
import {useForm, SubmitHandler, Controller} from "react-hook-form";
import {Form, Grid, GridItem, FormGroup, TextInput, ValidatedOptions, Button} from "@patternfly/react-core";
import _css from "./styles.module.scss";
import {TextEditor, SelectField} from 'components';
import {ExclamationCircleIcon} from "@patternfly/react-icons";
import {useMutation, useQuery} from "react-query";
import {GetArticleSections, AddArticle} from "api";

type Inputs = {
    title: string;
    content: any
    section: any
}

const CreateArticleForm:React.FC = () => {

    const accessToken = localStorage.getItem("x-access-token");
    const {
        control,
        handleSubmit,
        reset,
        formState: {isValid}
    } = useForm<Inputs>({
        mode: "onBlur"
    });
    const {data, isLoading} = useQuery([
        "getArticleSections", {
        access_token: accessToken
    }], GetArticleSections);
    const request = useMutation(AddArticle);

    const onSubmit:SubmitHandler<Inputs> = async (data) => {
        data.section = [data.section]
        await request.mutateAsync(data);
        reset({
            title: "",
            content: ""
        })
    }

    return (
        <Form onSubmit={handleSubmit(onSubmit)} className={_css.container}>
            <Grid hasGutter>
                <GridItem>
                    <Controller
                        name="title"
                        control={control}
                        defaultValue=""
                        rules={{required: true}}
                        render={(
                            {
                                field: { onChange, onBlur, value, name},
                                fieldState: {error}
                            }
                        ) => (
                            <FormGroup
                                label={"Titre"}
                                isRequired
                                fieldId={name}
                                validated={error ? "error": "default"}
                                helperTextInvalid={error && "Champ obligatoire."}
                                helperTextInvalidIcon={<ExclamationCircleIcon/>}
                                autoComplete="off"
                            >
                                <TextInput
                                    isRequired
                                    type="text"
                                    id={name}
                                    placeholder="Titre de l'article."
                                    name={name}
                                    value={value}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    validated={error && ValidatedOptions.error}
                                    className={_css.input}
                                />
                            </FormGroup>
                        )}
                    />
                </GridItem>
                <GridItem>
                    <Controller
                        name="section"
                        control={control}
                        defaultValue=""
                        rules={{required: true}}
                        render={({field, fieldState}) => (
                            <SelectField
                                dataOptions={data ? data: []}
                                placeholder={"Sélectionnez la section de l'article"}
                                {...field}
                                {...fieldState}
                            />
                        )}
                    />

                </GridItem>
                <GridItem>
                    <Controller
                        name="content"
                        control={control}
                        defaultValue=""
                        rules={{required: true}}
                        render={(props) => (
                            <TextEditor {...props}/>
                        )}
                    />
                </GridItem>
                <GridItem lg={2} lgOffset={10}>
                    <Button
                        isBlock
                        type="submit"
                        isDisabled={!isValid || isLoading || request.isLoading}
                        isLoading={request.isLoading}
                    >
                        Créer
                    </Button>
                </GridItem>
            </Grid>
        </Form>
    )

}

export {CreateArticleForm}

import {
    ACCOUNT_LOGIN_LINK,
    CHART_HOURS,
    CHECK_TOKEN_VALID,
    COUNT_ARTICLES,
    COUNT_CHRONICS,
    COUNT_USERS,
    CREATE_ARTICLES,
    CREATE_ARTICLES_SECTIONS,
    DELETE_ARTICLES,
    DELETE_CHRONICS,
    FETCH_ARTICLES,
    FETCH_ARTICLES_SECTIONS,
    FETCH_CHRONICS,
    FETCH_USERS,
    QUERY_OFFERS,
    QUERY_USER_SESSIONS,
    ROLES,
    SEARCH_USERS,
    USER_HOURS
} from "./urls";

class RequestServices {

    static login () {
        return {
            url: ACCOUNT_LOGIN_LINK,
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Origin": "*",
                "Credentials": "same-origin",
                "Content-Type": "application/json"
            },
            mode: "cors",
            cache: "default"
        };
    }

    static checkTokenValid (accessToken: string) {

        const params = new URLSearchParams()
        params.set("token", String(accessToken))

        return {
            url: CHECK_TOKEN_VALID + "?" + params,
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                "x-access-token": String(localStorage.getItem("x-access-token")),
            },
            requestStatus: true
        };
    }

    static countChronics () {
        return {
            url: COUNT_CHRONICS,
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                "x-access-token": String(localStorage.getItem("x-access-token")),
            },
            requestStatus: true
        };
    }

    static countUsers () {
        return {
            url: COUNT_USERS,
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                "x-access-token": String(localStorage.getItem("x-access-token")),
            },
            requestStatus: true
        };
    }

    static countArticles () {
        return {
            url: COUNT_ARTICLES,
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                "x-access-token": String(localStorage.getItem("x-access-token")),
            },
            requestStatus: true
        };
    }

    static getChronics(limit:number, page:number, id?: number) {

        const params = new URLSearchParams()
        params.set("limit", String(limit))
        params.set("page", String(page))
        id && params.set("id", String(id))

        return {
            url: FETCH_CHRONICS + "?" + params,
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                "x-access-token": String(localStorage.getItem("x-access-token")),
            },
            requestStatus: true
        };
    }

    static getArticles(limit:number, page:number, id?: number) {

        const params = new URLSearchParams()
        params.set("limit", String(limit))
        params.set("page", String(page))
        id && params.set("id", String(id))

        return {
            url: FETCH_ARTICLES + "?" + params,
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                "x-access-token": String(localStorage.getItem("x-access-token")),
            },
            requestStatus: true
        };
    }

    static deleteChronic (data) {
        return {
            url: DELETE_CHRONICS,
            method: "DELETE",
            headers: {
                "Accept": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Origin": "*",
                "Credentials": "same-origin",
                "Content-Type": "application/json",
                "x-access-token": String(localStorage.getItem("x-access-token")),
            },
            body: JSON.stringify(data),
            requestStatus: true
        };
    }

    static createArticle (data) {
        return {
            url: CREATE_ARTICLES,
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Origin": "*",
                "Credentials": "same-origin",
                "Content-Type": "application/json",
                "x-access-token": String(localStorage.getItem("x-access-token"))
            },
            body: JSON.stringify(data)
        }
    }

    static createArticleSection (data) {
        return {
            url: CREATE_ARTICLES_SECTIONS,
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Origin": "*",
                "Credentials": "same-origin",
                "Content-Type": "application/json",
                "x-access-token": String(localStorage.getItem("x-access-token"))
            },
            body: JSON.stringify(data)
        }
    }

    static getArticleSections(id?: number) {

        const params = new URLSearchParams()
        id && params.set("id", String(id))

        return {
            url: FETCH_ARTICLES_SECTIONS + "?" + params,
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                "x-access-token": String(localStorage.getItem("x-access-token")),
            },
            requestStatus: true
        };
    }

    static deleteArticles (data) {
        return {
            url: DELETE_ARTICLES,
            method: "DELETE",
            headers: {
                "Accept": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Origin": "*",
                "Credentials": "same-origin",
                "Content-Type": "application/json",
                "x-access-token": String(localStorage.getItem("x-access-token")),
            },
            body: JSON.stringify(data),
            requestStatus: true
        };
    }

    static getUsers (limit:number, page:number, id?: number) {

        const params = new URLSearchParams();
        params.set("limit", String(limit))
        params.set("page", String(page))
        id && params.set("id", String(id))

        return {
            url: `${FETCH_USERS}?limit=${limit}&page=${page}`,
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Origin": "*",
                "Credentials": "same-origin",
                "x-access-token": String(localStorage.getItem("x-access-token")),
            },
            requestStatus: true
        };
    }

    static updateUserHours (data: any) {

        return {
            url: USER_HOURS,
            method: "PUT",
            headers: {
                "Accept": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Origin": "*",
                "Credentials": "same-origin",
                "x-access-token": String(localStorage.getItem("x-access-token")),
            },
            requestStatus: true,
            body: JSON.stringify(data)
        };
    }

    static searchUsers (param:string) {

        const params = new URLSearchParams();
        params.set("param", param)

        return {
            url: SEARCH_USERS + "?" + params,
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Origin": "*",
                "Credentials": "same-origin",
                "x-access-token": String(localStorage.getItem("x-access-token")),
            },
            requestStatus: true
        };
    }

    static querySessions (id?:string) {

        const params = new URLSearchParams();
        id && params.set("id", id)

        return {
            url: QUERY_USER_SESSIONS + "?" + params,
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Origin": "*",
                "Credentials": "same-origin",
                "x-access-token": String(localStorage.getItem("x-access-token")),
            },
            requestStatus: true
        };
    }

    static queryStatsHours (year:number, month?:number) {

        const params = new URLSearchParams();
        month && params.set("month", String(month))
        params.set("period", String(year))

        return {
            url: USER_HOURS + "?" + params,
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Origin": "*",
                "Credentials": "same-origin",
                "x-access-token": String(localStorage.getItem("x-access-token")),
            },
            requestStatus: true
        };
    }

    static queryChartHours (year:number) {

        const params = new URLSearchParams();
        params.set("period", String(year))

        return {
            url: CHART_HOURS + "?" + params,
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Origin": "*",
                "Credentials": "same-origin",
                "x-access-token": String(localStorage.getItem("x-access-token")),
            },
            requestStatus: true
        };
    }

    static queryRoles () {
        return {
            url: ROLES,
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Origin": "*",
                "Credentials": "same-origin",
                "x-access-token": String(localStorage.getItem("x-access-token")),
            },
            requestStatus: true
        };
    }

    static queryOffers(limit:number, page:number, id?: number) {

        const params = new URLSearchParams()
        params.set("limit", String(limit))
        params.set("page", String(page))
        id && params.set("id", String(id))

        return {
            url: `${QUERY_OFFERS}?limit=${limit}&page=${page}&id=${id}`,
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                "x-access-token": String(localStorage.getItem("x-access-token")),
            },
            requestStatus: true
        };
    }

}

export {RequestServices};

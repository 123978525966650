import { Grid, GridItem, Text } from "@patternfly/react-core";
import React from "react";
import _css from './Main.module.scss';
import { FacebookSquareIcon } from "@patternfly/react-icons";

const Main: React.FC = () => {

    const currentYear = new Date().getFullYear();

    return (
        <footer>
            <Grid hasGutter className={_css.footer}>
                <GridItem lg={4}>
                    <Text><b>Nous joindre</b></Text>
                    <Text>Adresse :</Text>
                    <Text>4577 Boul. Guillaume-Couture, Levis, QC, G6W 6M6 418-603-3915</Text>
                </GridItem>
                <GridItem lg={4}>
                    <Text>banqueapitons@filon.ca</Text>
                    <Text>www.banqueapitons.org</Text>
                    <Text className={_css.link}>
                        <a href="https://www.facebook.com/groups/1036195870229928">
                            www.facebook.com/baquepitons
                        </a>
                    </Text>
                </GridItem>
                <GridItem lg={4} style={{ position: "relative" }}>
                    <FacebookSquareIcon
                        height={34}
                        width={34}
                        onClick={() => window.location.href = "https://www.facebook.com/groups/1036195870229928"}
                        style={{
                            position: "absolute",
                            bottom: 0,
                            right: 0,
                        }}
                    />
                </GridItem>
            </Grid>
            <div className={_css.copy_right}>
                <Text>Tous droits réservés 2013 - {currentYear} © Banque à pitons <span className={_css.copy_right_highlight}>Au-delà du partage, OSEZ !</span></Text>
            </div>
        </footer>
    )
}

export {
    Main as MainFooter
};


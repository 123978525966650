import React, {useEffect, useState} from 'react';
import {RouteComponentProps} from 'react-router-dom';
import {useRequestHttp} from "hooks";
import {RequestServices} from "services";
import _css from "./AuthenticationCheck.module.scss";
import {Bullseye, Button, ButtonVariant, Grid, GridItem, Text} from "@patternfly/react-core";
import {push} from "react-router-redirect";

interface Props {}

type CombinedProps = Props & RouteComponentProps

const AuthenticationChecker = (ComposedComponent) => {


    const Checker: React.FC<CombinedProps> = (props) => {

        const accessToken = localStorage.getItem("x-access-token");
        const [payload, isLoading] = useRequestHttp(RequestServices.checkTokenValid(String(accessToken)));
        const [statusCode, setStatusCode] = useState<number>(204);

        useEffect(() => {
            if (payload && payload["statusCode"]) {
                setStatusCode(payload["statusCode"]);
            }
        }, [payload]);

        const destroyAndRedirect = () => {
            return <React.Fragment>
                <div className={_css.container_failed}>
                    <Bullseye>
                        <Grid hasGutter className={_css.box}>
                            <GridItem lg={12}>
                                <Bullseye><img
                                    src={"https://bap.s3.eu-de.cloud-object-storage.appdomain.cloud/logo_pn5dfx_c_scale,w_293.png"}
                                    alt={"app-logo"}/></Bullseye>
                            </GridItem>
                            <GridItem lg={12}>
                                <Text>
                                    Si vous rencontrez cette page, cela signifie que votre session a expiré.
                                    Mais à l'arrière des boîtiers, il peut s'agir d'une erreur technique.
                                    Par conséquent, avant d'essayer de lancer une nouvelle session, essayez d'abord d'actualiser.
                                </Text>
                            </GridItem>
                            <GridItem lg={6}>
                                <Button
                                    onClick={() => push("/admin/sign_in")}
                                    variant={ButtonVariant.link}
                                    component={"a"}
                                    isBlock
                                >
                                    Se reconnecter
                                </Button>
                            </GridItem>
                            <GridItem lg={6}>
                                <Button
                                    onClick={() => window.location.reload()}
                                    variant={ButtonVariant.link}
                                    component={"a"}
                                    isBlock
                                >
                                    Rafraîchir
                                </Button>
                            </GridItem>
                        </Grid>
                    </Bullseye>
                </div>
            </React.Fragment>
        };

        return !accessToken || statusCode !== 204 ? (
            destroyAndRedirect()
        ) : (
            <>
                {!isLoading && <ComposedComponent {...props} />}
            </>
        );

    };
    return Checker
};

export {
    AuthenticationChecker
}

import {BASE} from "../services/urls";

export const API_GET_PUBLICATIONS = async ({queryKey}): Promise<ReturnType<() => any>> => {

    const options: any = {
        method: 'GET',
        headers: {
            'accept': 'application/json',
        }
    };
    const response = await fetch(`${BASE}/publications/`, options);
    const result = response.json();
    if (!response.ok) throw new Error(JSON.stringify(response.text()))
    return result
}

export const API_UPDATE_PUBLICATIONS = async (data): Promise<ReturnType<() => any>> => {

    const token = localStorage.getItem("x-imperso") || localStorage.getItem('x-access-token')
    const { id, title, content} = data;

    const options: any = {
        method: 'PUT',
        headers: {
            'content-type': 'application/json',
            'accept': 'application/json',
            'lang': localStorage.getItem('i18nextLng'),
            "x-access-token": token,
        },
        body: JSON.stringify({
            "id": data.id,
            "title": data.title,
            "content": data.content
        })
    };
    const response = await fetch(`${BASE}/publications/`, options);
    const result = response.json();
    // console.log(response)
    if (!response.ok) throw new Error(JSON.stringify(response.text()))
    return result
}
import React from 'react';
import _css from './HomeArticleHeader.module.scss';
import {Text} from "@patternfly/react-core";

interface Props {
    title: string
    titleWidth?: number
    optionWidth?: number
    option?: any
    smallScreenOption?: any
}

export const HomeArticleHeader:React.FC<Props> = (props) => {

    const {
        title,
        titleWidth,
        option,
        optionWidth,
        smallScreenOption
    } = props;

    return (
        <nav className={_css.header}>
            <div
                className={_css.title}
                style={{
                    flex: titleWidth && `${titleWidth}`
                }}
            >
                <Text>{title}</Text>
            </div>
            <div
                className={_css.option}
                style={{
                    flex: optionWidth && `${optionWidth}`
                }}
            >
                <span className={_css.smallScreenOption}>
                    {smallScreenOption ? smallScreenOption: option}
                </span>
                <span className={_css.largeScreenOption}>
                    {option}
                </span>
            </div>
        </nav>
    )
}

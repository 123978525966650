import {BASE} from "../services/urls";

export const AddArticle = async ( data ) => {

    const accessToken = localStorage.getItem("x-access-token");

    const options:any = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'accept': 'application/json',
            'lang': localStorage.getItem('i18nextLng'),
            "x-access-token": accessToken
        },
        body: JSON.stringify({
            "title": data.title,
            "content": data.content,
            "sections": data.section,
        })
    };

    const response = await fetch(`${BASE}/articles/`, options);
    const result = await response.json();

    if (!response.ok) throw new Error(result.message)
}


export const GetArticleSections = async ({ queryKey }) => {

    const [_key, { access_token }] = queryKey;

    const options:any = {
        method: 'GET',
        headers: {
            'accept': 'application/json',
            "x-access-token": access_token,
            'lang': localStorage.getItem('i18nextLng'),
            "key": _key
        }};

    if (access_token) {
        const response = await fetch(`${BASE}/articles/sections`, options);

        if (!response.ok) throw new Error(JSON.stringify(response.text()))

        return response.json()
    }
}

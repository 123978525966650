import React from "react";
import _css from "./DashboardHeader.module.scss";

const DashboardHeader = () => {
    return(
        <React.Fragment>
            <div className={_css.container}></div>
        </React.Fragment>
    )
}

export {DashboardHeader}

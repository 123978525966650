import React, {FC, Fragment, SetStateAction, useEffect} from 'react';
import {Alert, AlertVariant, Form, FormGroup, Grid, GridItem, TextInput} from "@patternfly/react-core";
import _css from './styles.module.scss';
import {Field, Form as FinalForm} from "react-final-form";
import {emailRegex} from "vars/Vars";
import {useMutation, useQueryClient} from "react-query";
import {AddUser, UpdateUser} from "api";
import SelectRoleOption from "./roles";

interface Props {
    defaultData?: any
    isUpdate?: boolean
    toggleFetching: SetStateAction<any>
}

const DashboardCreateUsersForm: FC<Props> = ({defaultData, isUpdate, toggleFetching}) => {

    const { mutateAsync, isError, error, isSuccess, isLoading} = useMutation(isUpdate ? UpdateUser: AddUser);
    const queryClient = useQueryClient();
    const access_token = localStorage.getItem("x-access-token");

    const handleSubmit = async (data) => {
        data.access_token = access_token
        console.log(data);
        await mutateAsync(data);
        await queryClient.invalidateQueries("getUsers");
    }

    const required = value => (value ? undefined : value);

    useEffect(() => {
        toggleFetching(isLoading)
    }, [isLoading, toggleFetching])

    return (
        <FinalForm
            onSubmit={handleSubmit}
            subscription={{
                submitting: true,
            }}
            initialValues={{
                firstname: defaultData ? defaultData.first_name : '',
                lastname: defaultData ? defaultData.last_name : '',
                email: defaultData ? defaultData.email : '',
                abv: defaultData ? defaultData.abv : '',
                file: defaultData ? defaultData.file : ''
            }}
            validate={values => {
                const errors: any = {};
                if (!values.firstname) {
                    errors["first_name"] = "Champ obligatoire"
                }
                if (!values.lastname) {
                    errors["last_name"] = "Champ obligatoire"
                }
                if (!values.email) {
                    errors["email"] = "Champ obligatoire"
                }
                if (values.email && !emailRegex.test(values.email)) {
                    errors["email"] = "Le format d'adresse e-mail non valide doit être example@example.com"
                }
                if (!values.role) {
                    errors["role"] = "Champ obligatoire";
                }
                return errors;
            }}
        >
            {({handleSubmit}) => (
                <Form onSubmit={handleSubmit} className={_css.container} id={"create-user-form"}>
                    {isError &&
                    <Alert
                        isInline
                        variant={AlertVariant.danger}
                        // @ts-ignore
                        title={<Fragment>{error && error.message}</Fragment>}
                    />}
                    {isSuccess &&
                    <Alert
                        isInline
                        variant={AlertVariant.success}
                        title="Utilisateur créé avec succès"
                    />}
                    <Grid hasGutter>
                        <GridItem lg={6} sm={12}>
                            <Field
                                name="firstname"
                                validate={required}
                                subscription={{
                                    value: true,
                                    active: true,
                                    error: true,
                                    touched: true
                                }}
                            >
                                {({ input, meta }) => (
                                    <FormGroup
                                        label="Prénom"
                                        isRequired
                                        fieldId={input.name}
                                        helperTextInvalid={meta.error && meta.touched && meta.error}
                                        validated={(meta.error && meta.touched) ? "error": "default"}
                                        autoComplete="off"
                                    >
                                        <TextInput
                                            validated={(meta.error && meta.touched) ? "error": "default"}
                                            isRequired
                                            type="text"
                                            id="firstname"
                                            placeholder="Prénom"
                                            onBlur={input.onBlur}
                                            onChange={input.onChange}
                                            name={input.name}
                                            defaultValue={defaultData ? defaultData.first_name : ""}
                                        />
                                    </FormGroup>
                                )}
                            </Field>
                        </GridItem>
                        <GridItem lg={6} sm={12}>
                            <Field
                                name="Nom de famille"
                                validate={required}
                                subscription={{
                                    value: true,
                                    active: true,
                                    error: true,
                                    touched: true
                                }}
                            >
                                {({ input, meta }) => (
                                    <FormGroup
                                        label="Nom de famille"
                                        isRequired
                                        fieldId={input.name}
                                        helperTextInvalid={meta.error && meta.touched && meta.error}
                                        validated={(meta.error && meta.touched) ? "error": "default"}
                                        autoComplete="off"
                                    >
                                        <TextInput
                                            validated={(meta.error && meta.touched) ? "error": "default"}
                                            isRequired
                                            type="text"
                                            id="lastname"
                                            placeholder="Nom de famille"
                                            onBlur={input.onBlur}
                                            onChange={input.onChange}
                                            name={input.name}
                                            defaultValue={defaultData ? defaultData.last_name : ""}
                                        />
                                    </FormGroup>
                                )}
                            </Field>
                        </GridItem>
                        <GridItem>
                            <Field
                                name="email"
                                validate={required}
                                subscription={{
                                    value: true,
                                    active: true,
                                    error: true,
                                    touched: true
                                }}
                            >
                                {({ input, meta }) => (
                                    <FormGroup
                                        label="E-mail"
                                        isRequired
                                        fieldId={input.name}
                                        helperTextInvalid={meta.error && meta.touched && meta.error}
                                        validated={(meta.error && meta.touched) ? "error": "default"}
                                        autoComplete="off"
                                    >
                                        <TextInput
                                            validated={(meta.error && meta.touched) ? "error": "default"}
                                            isRequired
                                            type="email"
                                            id="email"
                                            placeholder="E-mail"
                                            onBlur={input.onBlur}
                                            onChange={input.onChange}
                                            className={_css.emailAddress}
                                            name={input.name}
                                            defaultValue={defaultData ? defaultData.email : ""}
                                        />
                                    </FormGroup>
                                )}
                            </Field>
                        </GridItem>

                        <GridItem>
                            <Field
                                name="role"
                                component={SelectRoleOption}
                                subscription={{
                                    value: true,
                                    active: true,
                                    error: true,
                                    touched: true
                                }}
                                value={[]}
                                format={value => value ? value : isUpdate ? defaultData?.role: []}
                            />
                        </GridItem>

                    </Grid>
                </Form>
            )}
        </FinalForm>
    );
}

export default DashboardCreateUsersForm;

import React from "react";
import {Field, Form as FinalForm} from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import _css from "./SignUp.module.scss";
import variants from "sass/colors.module.scss";
import {Button, Grid, GridItem, Title} from "@patternfly/react-core";

interface Props {}

const SignUpForm:React.FC<Props> = () => {


    const required = value => (value ? undefined: 'sign-up-form-label.field-required');

    const onSubmit = (values) => {
    }

    return (
        <React.Fragment>
            <FinalForm
                onSubmit={onSubmit}
                subscription={{
                    submitting: true,
                }}
                mutators={{
                    // potentially other mutators could be merged here
                    ...arrayMutators
                }}
                validate={values => {
                    const errors = {};
                    if (!values.password) {
                        errors["password"] = "Required";
                    }
                    if (!values.confirm) {
                        errors["confirm"] = "Required";
                    } else if (values["confirm-password"] !== values.password) {
                        errors["confirm"] = "must-match-password-field";
                    }
                    return errors;
                }}
            >
                {({handleSubmit}) => (
                    <form onSubmit={handleSubmit} className={_css.container}>
                        <Grid hasGutter>
                            <GridItem>
                                <Title headingLevel={"h1"} className={_css.title}>
                                    Enregistrez votre compte pour commencer.
                                </Title>
                            </GridItem>
                            <GridItem>
                                <Field
                                    name="user_id"
                                    validate={required}
                                    subscription={{
                                        value: true,
                                        active: true,
                                        error: true,
                                        touched: true
                                    }}
                                >
                                    {({ input, meta }) => (
                                        <label htmlFor="user_id">
                                            <input
                                                className={[
                                                    _css.input,
                                                    (meta.error && meta.touched) && variants.error_input
                                                ].join(" ")}
                                                type="text"
                                                id="user_id"
                                                onBlur={input.onBlur}
                                                onChange={input.onChange}
                                                name={input.name}
                                                placeholder={"Nom d'utilisateur ou identifiant d'utilisateur"}
                                            />
                                        </label>
                                    )}
                                </Field>
                            </GridItem>
                            <GridItem lg={6}>
                                <Field
                                    name="email"
                                    validate={required}
                                    subscription={{
                                        value: true,
                                        active: true,
                                        error: true,
                                        touched: true
                                    }}
                                >
                                    {({ input, meta }) => (
                                        <label htmlFor="email">
                                            <input
                                                className={[
                                                    _css.input,
                                                    (meta.error && meta.touched) && variants.error_input
                                                ].join(" ")}
                                                type="text"
                                                id="email"
                                                onBlur={input.onBlur}
                                                onChange={input.onChange}
                                                name={input.name}
                                                placeholder={"Email"}
                                            />
                                        </label>
                                    )}
                                </Field>
                            </GridItem>
                            <GridItem lg={6}>
                                <Field
                                    name="email_confirm"
                                    validate={required}
                                    subscription={{
                                        value: true,
                                        active: true,
                                        error: true,
                                        touched: true
                                    }}
                                >
                                    {({ input, meta }) => (
                                        <label htmlFor="email_confirm">
                                            <input
                                                className={[
                                                    _css.input,
                                                    (meta.error && meta.touched) && variants.error_input
                                                ].join(" ")}
                                                type="text"
                                                id="email_confirm"
                                                onBlur={input.onBlur}
                                                onChange={input.onChange}
                                                name={input.name}
                                                placeholder={"Confirmer l'email"}
                                            />
                                        </label>
                                    )}
                                </Field>
                            </GridItem>
                            <GridItem lg={6}>
                                <Field
                                    name="password"
                                    validate={required}
                                    subscription={{
                                        value: true,
                                        active: true,
                                        error: true,
                                        touched: true
                                    }}
                                >
                                    {({ input, meta }) => (
                                        <label htmlFor="password">
                                            <input
                                                className={[
                                                    _css.input,
                                                    (meta.error && meta.touched) && variants.error_input
                                                ].join(" ")}
                                                type="password"
                                                id="password"
                                                onBlur={input.onBlur}
                                                onChange={input.onChange}
                                                name={input.name}
                                                placeholder={"Mot de passe"}
                                            />
                                        </label>
                                    )}
                                </Field>
                            </GridItem>
                            <GridItem lg={6}>
                                <Field
                                    name="password_confirm"
                                    validate={required}
                                    subscription={{
                                        value: true,
                                        active: true,
                                        error: true,
                                        touched: true
                                    }}
                                >
                                    {({ input, meta }) => (
                                        <label htmlFor="password_confirm">
                                            <input
                                                className={[
                                                    _css.input,
                                                    (meta.error && meta.touched) && variants.error_input
                                                ].join(" ")}
                                                type="text"
                                                id="password_confirm"
                                                onBlur={input.onBlur}
                                                onChange={input.onChange}
                                                name={input.name}
                                                placeholder={"Confirmer le mot de passe"}
                                            />
                                        </label>
                                    )}
                                </Field>
                            </GridItem>
                            <GridItem>
                                <Field
                                    name="title"
                                    validate={required}
                                    subscription={{
                                        value: true,
                                        active: true,
                                        error: true,
                                        touched: true
                                    }}
                                >
                                    {({ input, meta }) => (
                                        <label htmlFor="title">
                                            <select
                                                id={"title"}
                                                onBlur={input.onBlur}
                                                onChange={input.onChange}
                                                name={input.name}
                                            >
                                                <option>Monsieur</option>
                                                <option>Madame</option>
                                            </select>
                                        </label>
                                    )}
                                </Field>
                            </GridItem>
                            <GridItem lg={6}>
                                <Field
                                    name="first_name"
                                    validate={required}
                                    subscription={{
                                        value: true,
                                        active: true,
                                        error: true,
                                        touched: true
                                    }}
                                >
                                    {({ input, meta }) => (
                                        <label htmlFor="first_name">
                                            <input
                                                className={[
                                                    _css.input,
                                                    (meta.error && meta.touched) && variants.error_input
                                                ].join(" ")}
                                                type="text"
                                                id="first_name"
                                                onBlur={input.onBlur}
                                                onChange={input.onChange}
                                                name={input.name}
                                                placeholder={"Prénom"}
                                            />
                                        </label>
                                    )}
                                </Field>
                            </GridItem>
                            <GridItem lg={6}>
                                <Field
                                    name="last_name"
                                    validate={required}
                                    subscription={{
                                        value: true,
                                        active: true,
                                        error: true,
                                        touched: true
                                    }}
                                >
                                    {({ input, meta }) => (
                                        <label htmlFor="last_name">
                                            <input
                                                className={[
                                                    _css.input,
                                                    (meta.error && meta.touched) && variants.error_input
                                                ].join(" ")}
                                                type="text"
                                                id="last_name"
                                                onBlur={input.onBlur}
                                                onChange={input.onChange}
                                                name={input.name}
                                                placeholder={"Nom"}
                                            />
                                        </label>
                                    )}
                                </Field>
                            </GridItem>
                            <GridItem>
                                <Field
                                    name="phone"
                                    validate={required}
                                    subscription={{
                                        value: true,
                                        active: true,
                                        error: true,
                                        touched: true
                                    }}
                                >
                                    {({ input, meta }) => (
                                        <label htmlFor="phone">
                                            <input
                                                className={[
                                                    _css.input,
                                                    (meta.error && meta.touched) && variants.error_input
                                                ].join(" ")}
                                                type="text"
                                                id="phone"
                                                onBlur={input.onBlur}
                                                onChange={input.onChange}
                                                name={input.name}
                                                placeholder={"Numéro de téléphone"}
                                            />
                                        </label>
                                    )}
                                </Field>
                            </GridItem>
                            <GridItem lg={2}>
                                <Button
                                    type={"submit"}
                                    isBlock
                                    className={_css.submit_button}>
                                    S'inscrire
                                </Button>
                            </GridItem>
                        </Grid>
                    </form>
                )}
            </FinalForm>
        </React.Fragment>
    )
}

export {SignUpForm};

import React, { createElement, Fragment, startTransition, useCallback, useEffect, useRef, useState } from "react";
import { Field, Form as FinalForm } from "react-final-form";
import arrayMutators from "final-form-arrays";
import _css from "./UpdateProfileForm.module.scss";
import styles from "./UpdateProfileForm.module.scss";
import {
    Alert,
    AlertActionCloseButton,
    Button,
    ButtonVariant,
    Divider,
    Form,
    Grid,
    GridItem,
    Icon,
    List,
    ListItem,
    Popover,
    Skeleton,
    Spinner,
    Text,
    TextArea,
    TextContent,
    Title
} from "@patternfly/react-core";
import InfoCircleIcon from '@patternfly/react-icons/dist/esm/icons/info-circle-icon';
import variants from "sass/colors.module.scss";
import useRequestFetch from "react-requests-fetch";
import { DECODE_SESSION_TOKEN, UPDATE_SESSION_ACCOUNT_INFORMATION } from "services/urls";
import { FieldArray } from 'react-final-form-arrays';
import { CloseIcon, PlusIcon } from "@patternfly/react-icons";
import PlacesAutocomplete, { geocodeByAddress } from "react-places-autocomplete";
import { GoLocation } from "react-icons/go";

type Message = {
    variant: "success" | "danger" | "default" | "warning" | "info" | undefined
    message: string
} | undefined

const UpdateProfileForm: React.FC = () => {

    const accessToken = localStorage.getItem("x-imperso") || localStorage.getItem('x-access-token');
    const [fetching, setFetching] = useState<boolean>(true);
    const [account, setAccount] = useState<any>();
    const [message, setMessage] = useState<Message>();
    const [searchError, setSearchError] = useState<any>();
    const [searchValue, setSearchValue] = useState<string>("");
    const [locationResults, setLocationResults] = useState<any>();
    const [address, setAddress] = useState<{
        text: string
        streetName?: string,
        houseNumber?: string,
        streetType?: string,
        apartmentNumber?: string,
        city?: string,
        postalCode?: string
        province?: string,
        neighborhood?: string
    }>({
        text: "",
        streetName: "",
        houseNumber: "",
        streetType: "",
        apartmentNumber: "",
        city: "",
        postalCode: "",
        province: "",
        neighborhood: ""
    })
    const locationInputRef = useRef<any>();

    const [reqAccount, getReqAccount] = useRequestFetch({
        uri: DECODE_SESSION_TOKEN,
        headers: { "x-access-token": String(accessToken) }
    });

    const required = value => (value ? undefined : 'sign-up-form-label.field-required');

    const handleChange = useCallback((value) => {
        startTransition(() => {
            setSearchValue(value)
        });
    }, []);

    const handleSelect = async (value) => {

        const results = await geocodeByAddress(value);
        const addressComponents = results[0].address_components;

        console.log(addressComponents);

        const streetComponent = addressComponents.find(
            (component) => component.types.includes('route')
        );
        const houseNumberComponent = addressComponents.find(
            (component) => component.types.includes('street_number')
        );
        const apartmentNumberComponent = addressComponents.find(
            (component) => component.types.includes('subpremise')
        );
        const cityComponent = addressComponents.find(
            (component) => component.types.includes('locality') || component.types.includes('administrative_area_level_1')
        );
        const postalCodeComponent = addressComponents.find(
            (component) => component.types.includes('postal_code')
        );
        const provinceObj = addressComponents.find(
            (component) => component.types.includes('administrative_area_level_1')
        );
        const neighborhoodObj = addressComponents.find(
            (component) => component.types.includes('neighborhood') ||
                component.types.includes('sublocality') ||
                component.types.includes('sublocality_level_1')
        );

        const { long_name: streetName, short_name: streetType } = streetComponent || {};
        const { long_name: houseNumber } = houseNumberComponent || {};
        const { long_name: apartmentNumber } = apartmentNumberComponent || {};
        const { long_name: city } = cityComponent || {};
        const { long_name: postalCode } = postalCodeComponent || {};
        const { long_name: province } = provinceObj || {};
        const { long_name: neighborhood } = neighborhoodObj || {};

        setSearchValue(value);
        setAddress({
            text: value,
            streetName,
            houseNumber,
            streetType,
            apartmentNumber,
            city,
            postalCode,
            province,
            neighborhood
        });

        /***
         *  When sending data to the backend ensure the address line [ AddressLine ] is used as the neighborhood.
         *  First update the column to get all the extra information data of the use and key the format of extra_properties.
         *  Update the location given the required field and add the missing ones like PostalCode province.
         *
         *  ***/
    }

    const handleSubmit = (values) => {
        values.location = searchValue ? searchValue : account?.extra_information?.location
        values.extra_properties = address
        console.log(values.marital_status)
        // console.log(values)
        setFetching(true)
        fetch(UPDATE_SESSION_ACCOUNT_INFORMATION, {
            method: "PUT",
            headers: {
                "x-access-token": String(accessToken),
                "Content-Type": "application/json",
            },
            body: JSON.stringify(values)
        })
            .then((response) => response.status)
            .then((data) => {
                data === 201 ?
                    setMessage({
                        "variant": "success",
                        "message": "Les informations du compte ont été mises à jour avec succès!"
                    }) :
                    setMessage({
                        "variant": "danger",
                        "message": "Oops, nous sommes désolés erreur de serveur échouée par l'opérateur, réessayez si cela persiste, veuillez contacter le support technique!"
                    })
            }).finally(() => getReqAccount({
                uri: DECODE_SESSION_TOKEN,
                headers: { "x-access-token": String(accessToken) }
            }))
    }

    useEffect(() => {
        if (reqAccount && reqAccount.statusCode === 200) {
            setAccount(reqAccount.payload)
            setFetching(false)
            console.log(account)
        }
        // eslint-disable-next-line
    }, [reqAccount])

    const popInterest = (idx) => {
        let interests = account["extra_information"]["interests"];
        interests.length === 1 ? interests = [] :
            interests = interests.splice(interests[idx], 0)
        account["extra_information"]["interests"] = interests
        setAccount(account)
    }

    return (
        <Fragment>
            {account && <FinalForm
                onSubmit={handleSubmit}
                subscription={{
                    submitting: true,
                }}
                initialValues={{
                    firstname: account && account.first_name,
                    lastname: account && account.last_name,
                    username: account && account.username,
                    email: account && account.email,
                    number_of_children: (account && account.extra_information) ? String(account.extra_information.number_of_children) : "",
                    marital_status: (account && account.extra_information) ? String(account.extra_information.marital_status) : "",
                    // biography: (account && account.extra_information) ? String(account.extra_information.biography) : "",
                    // occupation: (account && account.extra_information) ? account && account.extra_information.occupation : "",
                    // interests: (account && account.extra_information) ? account && account.extra_information.interests : [],
                    postal_code: (account && account.extra_information) ? account && account.extra_information.postal_code : "",
                    date_of_birth: (account && account.extra_information) ? account && account.extra_information.date_of_birth : "",
                    location: account?.extra_information?.location,
                }}
                mutators={{
                    ...arrayMutators
                }}
            >
                {({
                    handleSubmit,
                    form: {
                        mutators: {
                            push, // pop too
                        }
                    } // injected from final-form-arrays above
                }) => {

                    return (
                        <Form
                            onSubmit={handleSubmit}
                            className={_css.container}
                        >
                            <Grid hasGutter>
                                <GridItem>
                                    <Title headingLevel={"h3"} size="3xl">
                                        Les informations du compte
                                    </Title>
                                    <GridItem lg={10} sm={12}>
                                        <Text>
                                            Mettez à jour les informations de votre compte avec les informations
                                            fournies ci-dessous.
                                        </Text>
                                    </GridItem>
                                </GridItem>
                                <GridItem lg={6}>
                                    {fetching && <Skeleton height="50px" screenreaderText="Loading contents" />}
                                    {!fetching && <Field
                                        name="firstname"
                                        validate={required}
                                        subscription={{
                                            value: true,
                                            active: true,
                                            error: true,
                                            touched: true
                                        }}
                                    >
                                        {({
                                            input,
                                            meta
                                        }) => (
                                            <label htmlFor="firstname">
                                                <span className={_css.labels}>Prénom</span>
                                                <input
                                                    className={[
                                                        _css.input,
                                                        (meta.error && meta.touched) && variants.error_input
                                                    ].join(" ")}
                                                    type="text"
                                                    id="firstname"
                                                    onBlur={input.onBlur}
                                                    onChange={input.onChange}
                                                    name={input.name}
                                                    placeholder={"Prénom"}
                                                    defaultValue={account && account.first_name}
                                                />
                                            </label>
                                        )}
                                    </Field>}
                                </GridItem>
                                <GridItem lg={6}>
                                    {fetching && <Skeleton height="50px" screenreaderText="Loading contents" />}
                                    {!fetching && <Field
                                        name="lastname"
                                        validate={required}
                                        subscription={{
                                            value: true,
                                            active: true,
                                            error: true,
                                            touched: true
                                        }}
                                    >
                                        {({
                                            input,
                                            meta
                                        }) => (
                                            <label htmlFor="lastname">
                                                <span className={_css.labels}>Nom de famille</span>
                                                <input
                                                    className={[
                                                        _css.input,
                                                        (meta.error && meta.touched) && variants.error_input
                                                    ].join(" ")}
                                                    type="text"
                                                    id="lastname"
                                                    onBlur={input.onBlur}
                                                    onChange={input.onChange}
                                                    name={input.name}
                                                    placeholder={"Nom de famille"}
                                                    defaultValue={account && account.last_name}
                                                />
                                            </label>
                                        )}
                                    </Field>}
                                </GridItem>
                                <GridItem lg={12}>
                                    {fetching && <Skeleton height="50px" screenreaderText="Loading contents" />}
                                    {!fetching && <Field
                                        name="username"
                                        validate={required}
                                        subscription={{
                                            value: true,
                                            active: true,
                                            error: true,
                                            touched: true
                                        }}
                                    >
                                        {({
                                            input,
                                            meta
                                        }) => (
                                            <label htmlFor="username">
                                                <span className={_css.labels}>Nom d'utilisateur</span>
                                                <input
                                                    className={[
                                                        _css.input,
                                                        (meta.error && meta.touched) && variants.error_input
                                                    ].join(" ")}
                                                    type="text"
                                                    id="username"
                                                    onBlur={input.onBlur}
                                                    onChange={input.onChange}
                                                    name={input.name}
                                                    placeholder={"Nom d'utilisateur"}
                                                    defaultValue={account && account.username}
                                                />
                                            </label>
                                        )}
                                    </Field>}
                                </GridItem>
                                <GridItem lg={12}>
                                    {fetching && <Skeleton height="50px" screenreaderText="Loading contents" />}
                                    {!fetching && <Field
                                        name="email"
                                        validate={required}
                                        subscription={{
                                            value: true,
                                            active: true,
                                            error: true,
                                            touched: true
                                        }}
                                    >
                                        {({
                                            input,
                                            meta
                                        }) => (
                                            <label htmlFor="email">
                                                <span className={_css.labels}>Adresse e-mail</span>
                                                <input
                                                    className={[
                                                        _css.input,
                                                        (meta.error && meta.touched) && variants.error_input
                                                    ].join(" ")}
                                                    type="email"
                                                    id="email"
                                                    onBlur={input.onBlur}
                                                    onChange={input.onChange}
                                                    name={input.name}
                                                    placeholder={"Adresse e-mail"}
                                                    defaultValue={account && account.email}
                                                />
                                            </label>
                                        )}
                                    </Field>}
                                </GridItem>
                                {/* <GridItem lg={12}>
                                    {fetching && <Skeleton height="50px" screenreaderText="Loading contents" />}
                                    {!fetching && <Field
                                        name="occupation"
                                        subscription={{
                                            value: true,
                                            active: true,
                                            error: true,
                                            touched: true
                                        }}
                                    >
                                        {({
                                            input,
                                            meta
                                        }) => (
                                            <label htmlFor="occupation">
                                                <span className={_css.labels}>Occupation</span>
                                                <input
                                                    className={[
                                                        _css.input,
                                                        (meta.error && meta.touched) && variants.error_input
                                                    ].join(" ")}
                                                    type="text"
                                                    id="occupation"
                                                    onBlur={input.onBlur}
                                                    onChange={input.onChange}
                                                    name={input.name}
                                                    placeholder={"Occupation"}
                                                    defaultValue={(account && account.extra_information) ? account.extra_information.occupation : ""}
                                                />
                                            </label>
                                        )}
                                    </Field>}
                                </GridItem> */}
                                <GridItem lg={12}>
                                    {fetching && <Skeleton height="50px" screenreaderText="Loading contents" />}
                                    {!fetching && <Field
                                        name="date_of_birth"
                                        validate={required}
                                        subscription={{
                                            value: true,
                                            active: true,
                                            error: true,
                                            touched: true
                                        }}
                                    >
                                        {({
                                            input,
                                            meta
                                        }) => (
                                            <label htmlFor="date_of_birth">
                                                <span className={_css.labels}>Date de naissance</span>
                                                <input
                                                    className={[
                                                        _css.input,
                                                        (meta.error && meta.touched) && variants.error_input
                                                    ].join(" ")}
                                                    type="date"
                                                    id="date_of_birth"
                                                    onBlur={input.onBlur}
                                                    onChange={input.onChange}
                                                    name={input.name}
                                                    placeholder={"Date de naissance"}
                                                    defaultValue={(account && account.extra_information) ? account.extra_information.date_of_birth : ""}
                                                />
                                            </label>
                                        )}
                                    </Field>}
                                </GridItem>

                                <GridItem lg={12}>
                                    {fetching && <Skeleton height="50px" screenreaderText="Loading contents" />}
                                    {!fetching && (
                                        <Field
                                            name="location"
                                            subscription={{
                                                value: true,
                                                active: true,
                                                error: true,
                                                touched: true
                                            }}
                                        >
                                            {({
                                                input,
                                                meta
                                            }) => (
                                                <PlacesAutocomplete
                                                    value={searchValue}
                                                    onChange={handleChange}
                                                    onSelect={handleSelect}
                                                    debounce={200}
                                                    shouldFetchSuggestions={true}
                                                >
                                                    {({ getInputProps, suggestions, loading, getSuggestionItemProps }) => (
                                                        <label htmlFor="location">
                                                            <span className={_css.labels}>Adresse du domicile</span>
                                                            <input
                                                                className={[
                                                                    _css.input,
                                                                    (meta.error && meta.touched) && variants.error_input
                                                                ].join(" ")}
                                                                type="text"
                                                                id="location"
                                                                ref={locationInputRef}
                                                                onBlur={input.onBlur}
                                                                onChange={(e) => handleChange(e.target.value)}
                                                                name={input.name}
                                                                value={searchValue}
                                                                defaultValue={account?.extra_information?.location}
                                                                placeholder={account?.extra_information?.location}
                                                                {...getInputProps({
                                                                    placeholder: account.extra_information.location,
                                                                    default: account.extra_information.location,
                                                                    className: styles.input,
                                                                })}
                                                            />
                                                            {suggestions.length > 0 && (
                                                                <List isPlain isBordered className={styles.options}>
                                                                    {suggestions.map((suggestion, index) => {
                                                                        const { description, location, locality } = suggestion;
                                                                        const className = suggestion.active
                                                                            ? "suggestion-item--active"
                                                                            : "suggestion-item";
                                                                        return (
                                                                            <ListItem {...getSuggestionItemProps(suggestion, { className })} key={index}>
                                                                                <TextContent>
                                                                                    <Text component="small">{createElement(GoLocation)}{' '}{description}</Text>
                                                                                </TextContent>
                                                                            </ListItem>
                                                                        );
                                                                    })}
                                                                </List>
                                                            )}
                                                        </label>
                                                    )}
                                                </PlacesAutocomplete>
                                            )}
                                        </Field>
                                    )}
                                </GridItem>

                                <GridItem lg={12}>
                                    {fetching && <Skeleton height="50px" screenreaderText="Loading contents" />}
                                    {!fetching && (<Field
                                        name="marital_status"
                                        subscription={{
                                            value: true,
                                            active: true,
                                            error: true,
                                            touched: true
                                        }}
                                    >
                                        {({
                                            input,
                                            meta
                                        }) => (
                                            <label htmlFor="martial_status">
                                                <span className={_css.labels}>Statut matrimonial </span>
                                                <Popover
                                                    aria-label="Basic popover"
                                                    headerContent={<div>Information Banque à Pitons</div>}
                                                    bodyContent={<div>Ces informations demeurent confidentielles et sont à l'usage unique de l'administration de la Banque à pitons pour des fins de statistiques. .</div>}
                                                // footerContent="Popover footer"
                                                >
                                                    {/* <Button>Toggle popover</Button> */}
                                                    <Icon status="info">
                                                        <InfoCircleIcon>Toggle popover</InfoCircleIcon>
                                                    </Icon>
                                                </Popover>
                                                <select
                                                    defaultValue={account && account.extra_information.marital_status}
                                                    onChange={input.onChange}
                                                    onBlur={input.onBlur}
                                                    name={input.name}
                                                    id="marital_status"
                                                    className={[
                                                        _css.input,
                                                        (meta.error && meta.touched) && variants.error_input
                                                    ].join(" ")}
                                                >
                                                    <option value="N" disabled>
                                                        Sélectionnez votre statut matrimonial *
                                                    </option>
                                                    <option value="C">Célibataire (ne vivant pas en union libre)</option>
                                                    <option value="M">Marié(e)</option>
                                                    {/* <option value="D">Divorcé(e)</option> */}
                                                    <option value="F">Conjoint(e) de fait</option>
                                                    <option value="V">Veuf(ve)</option>
                                                </select>
                                                {/* {(meta.error && meta.touched) &&
                                                    <InputErrors message={meta.error} />} */}
                                            </label>
                                        )}
                                    </Field>
                                    )}
                                </GridItem>
                                <GridItem lg={12}>
                                    {fetching && <Skeleton height="50px" screenreaderText="Loading contents" />}
                                    {!fetching && (
                                        <Field
                                            name="number_of_children"
                                            subscription={{
                                                value: true,
                                                active: true,
                                                error: true,
                                                touched: true
                                            }}
                                        >
                                            {({
                                                input,
                                                meta
                                            }) => (
                                                <label htmlFor="number_of_children">
                                                    <span className={_css.labels}>Nombre d'enfant </span>
                                                    <Popover
                                                        aria-label="Basic popover"
                                                        headerContent={<div>Information Banque à Pitons</div>}
                                                        bodyContent={<div>Ces informations demeurent confidentielles et sont à l'usage unique de l'administration de la Banque à pitons pour des fins de statistiques. .</div>}
                                                    // footerContent="Popover footer"
                                                    >
                                                        {/* <Button>Toggle popover</Button> */}
                                                        <Icon status="info">
                                                            <InfoCircleIcon>Toggle popover</InfoCircleIcon>
                                                        </Icon>
                                                    </Popover>
                                                    <input
                                                        className={[
                                                            _css.input,
                                                            (meta.error && meta.touched) && variants.error_input
                                                        ].join(" ")}
                                                        type="number"
                                                        id="hours"
                                                        onBlur={input.onBlur}
                                                        onChange={input.onChange}
                                                        name={input.name}
                                                        placeholder={"Nombre d'enfant"}
                                                        value={input.value}
                                                        defaultValue={account && account.number_of_children}
                                                    />
                                                    {/* {error && <InputErrors message={error?.message as any} />} */}
                                                    {/* {(meta.error && meta.touched) &&
                                                    <InputErrors message={meta.error} />} */}
                                                </label>
                                            )}
                                        </Field>
                                    )}
                                </GridItem>
                                {/* <GridItem lg={12}>
                                    {fetching &&
                                        <div style={{ height: '200px' }}>
                                            <Skeleton height="100%" width="100%"
                                                screenreaderText="Loading medium rectangle contents" />
                                        </div>}
                                    {!fetching && <Field
                                        name="biography"
                                        subscription={{
                                            value: true,
                                            active: true,
                                            error: true,
                                            touched: true
                                        }}
                                    >
                                        {({
                                            input,
                                            meta
                                        }) => (
                                            <label htmlFor="biography">
                                                <span className={_css.labels}>Biographie</span>
                                                <TextArea
                                                    className={[
                                                        _css.input,
                                                        (meta.error && meta.touched) && variants.error_input
                                                    ].join(" ")}
                                                    style={{ height: 200 }}
                                                    type="text"
                                                    id="biography"
                                                    onBlur={input.onBlur}
                                                    onChange={input.onChange}
                                                    name={input.name}
                                                    placeholder={"Biographie"}
                                                    defaultValue={(account && account.extra_information) ? account.extra_information.biography : ""}
                                                />
                                            </label>
                                        )}
                                    </Field>}
                                </GridItem>
                                <Divider />
                                {fetching &&
                                    <div style={{ height: '60px' }}>
                                        <Skeleton height="100%" width="100%"
                                            screenreaderText="Loading medium rectangle contents" />
                                    </div>}

                                {!fetching &&
                                    <GridItem>
                                        <Title headingLevel={"h2"}>Intérêts</Title>
                                        <GridItem>
                                            <Grid hasGutter>
                                                <FieldArray name="interests">
                                                    {({ fields }) =>
                                                        fields.map((name, index) => (
                                                            <Fragment key={name}>
                                                                <label>Opt. #{index + 1}</label>
                                                                <GridItem>
                                                                    <Field
                                                                        name={name}
                                                                        validate={required}
                                                                        subscription={{
                                                                            value: true,
                                                                            active: true,
                                                                            error: true,
                                                                            touched: true
                                                                        }}
                                                                    >
                                                                        {({
                                                                            input,
                                                                            meta
                                                                        }) => (
                                                                            <input
                                                                                className={[
                                                                                    _css.input,
                                                                                    (meta.error && meta.touched) && variants.error_input
                                                                                ].join(" ")}
                                                                                type="text"
                                                                                id={name}
                                                                                onBlur={input.onBlur}
                                                                                onChange={input.onChange}
                                                                                name={input.name}
                                                                                defaultValue={(account && account.extra_information) ? account.extra_information.interests[index] : []}
                                                                            />
                                                                        )}
                                                                    </Field>
                                                                </GridItem>
                                                                <GridItem lg={2}>
                                                                    <Button
                                                                        variant={ButtonVariant.tertiary}
                                                                        isBlock
                                                                        onClick={() => {
                                                                            popInterest(index)
                                                                            fields.remove(index)
                                                                        }}
                                                                        style={{ cursor: 'pointer' }}
                                                                    >
                                                                        <CloseIcon color={"#FF0000"} />
                                                                    </Button>
                                                                </GridItem>
                                                            </Fragment>
                                                        ))
                                                    }
                                                </FieldArray>
                                            </Grid>
                                        </GridItem>
                                    </GridItem>} */}
                                {(!fetching && message) &&
                                    <GridItem>
                                        <Alert isInline variant={message.variant || "default"}
                                            title={"Mise à jour du compte"}
                                            actionClose={<AlertActionCloseButton
                                                onClose={() => setMessage(undefined)} />}>
                                            <Text>
                                                {message.message}
                                            </Text>
                                        </Alert>
                                    </GridItem>}
                                {/* {!fetching &&
                                    <GridItem>
                                        <Button
                                            type="button"
                                            isDisabled={fetching}
                                            variant={ButtonVariant.tertiary}
                                            onClick={() => push('interests', undefined)}
                                        >
                                            <PlusIcon />
                                        </Button>
                                    </GridItem>} */}

                                <GridItem>
                                    <Button isDisabled={fetching} type={"submit"}>
                                        {fetching ? <Spinner size="sm" /> : <span>Enregistrer</span>}
                                    </Button>
                                </GridItem>
                            </Grid>
                        </Form>
                    )
                }}
            </FinalForm>}
        </Fragment>
    )
}

export default UpdateProfileForm;

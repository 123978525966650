import {Editor} from '@tinymce/tinymce-react';
import React from "react";
import _css from "./styles.module.scss";

const TextEditor = props => {

    const handleEditorChange = (e) => {
        props.field.onChange(e.target.getContent())
    };

    return (
        <div className={props.fieldState.error && _css.error}>
            <Editor
                initialValue={props.field.value}
                apiKey="osv8722vdzi6swvc8g08x9zwpcn8ylp9nck7qbr2ti8iail5"
                init={{
                    plugins: [
                        'advlist autolink lists link image',
                        'charmap print preview anchor help',
                        'searchreplace visualblocks code',
                        'insertdatetime media table paste wordcount'
                    ],
                    toolbar:
                        `undo redo | formatselect | bold italic | alignleft aligncenter alignright | 
                     bullist numlist outdent indent | help
                     `,
                    /* enable title field in the Image dialog*/
                    image_title: true,
                    height: 400,
                    automatic_uploads: true,
                    file_picker_types: 'image',
                    /* and here's our custom image picker*/
                    file_picker_callback: function (cb, value, meta) {
                        const input = document.createElement('input');
                        input.setAttribute('type', 'file');
                        input.setAttribute('accept', 'image/*');
                        input.onchange = function () {
                            // @ts-ignore
                            const file = this.files[0];
                            const reader = new FileReader();

                            reader.onload = function () {
                                const id = 'blobid' + (new Date()).getTime();
                                // @ts-ignore
                                const blobCache =  tinymce.activeEditor.editorUpload.blobCache;
                                // @ts-ignore
                                const base64 = reader.result.split(',')[1];
                                const blobInfo = blobCache.create(id, file, base64);
                                blobCache.add(blobInfo);

                                /* call the callback and populate the Title field with the file name */
                                cb(blobInfo.blobUri(), { title: file.name });
                            };
                            reader.readAsDataURL(file);
                        };

                        input.click();
                    },
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                }}
                onChange={handleEditorChange}
                onBlur={props.field.onBlur}
            />
        </div>
    )
}

export default TextEditor;

import React, {useState} from "react";
import {Field, Form as FinalForm} from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import _css from "./AdminUpdateAccount.module.scss";
import variants from "sass/colors.module.scss";
import {Grid, GridItem, Switch} from "@patternfly/react-core";
import {RolesComponent} from "./roles/Roles.page";

interface Props {
    info: any
}

const AdminUpdateAccountForm:React.FC<Props> = (props) => {

    const required = value => (value ? undefined: 'sign-up-form-label.field-required');
    const [loading] = useState<boolean>(false);
    const {info} = props;

    const onSubmit = (values) => {

    }

    return (
        <React.Fragment>
            <FinalForm
                onSubmit={onSubmit}
                subscription={{
                    submitting: true,
                }}
                mutators={{
                    // potentially other mutators could be merged here
                    ...arrayMutators
                }}
                validate={values => {
                    const errors = {};
                    if (!values.firstname) {
                        errors["firstname"] = "Required";
                    }
                    if (!values.lastname) {
                        errors["lastname"] = "Required";
                    }
                    if (!values.username) {
                        errors["username"] = "Required";
                    }
                    if (!values.email) {
                        errors["email"] = "Required";
                    }
                    if (!values.roles) {
                        errors["roles"] = "Required";
                    }
                    return errors;
                }}
            >
                {({handleSubmit}) => (
                    <form onSubmit={handleSubmit} className={_css.container}>
                        <Grid hasGutter>
                            <GridItem>
                                <Grid hasGutter className={_css.form_components}>
                                    <GridItem lg={6}>
                                        <Field
                                            name="firstname"
                                            validate={required}
                                            subscription={{
                                                value: true,
                                                active: true,
                                                error: true,
                                                touched: true
                                            }}
                                            defaultValue={info && info.first_name}
                                        >
                                            {({ input, meta }) => (
                                                <label htmlFor="firstname">
                                                    <input
                                                        className={[
                                                            _css.input,
                                                            (meta.error && meta.touched) && variants.error_input
                                                        ].join(" ")}
                                                        type="text"
                                                        id="firstname"
                                                        defaultValue={info && info.first_name}
                                                        onBlur={input.onBlur}
                                                        onChange={input.onChange}
                                                        name={input.name}
                                                        placeholder={"Nom d'utilisateur"}
                                                    />
                                                </label>
                                            )}
                                        </Field>
                                    </GridItem>
                                    <GridItem lg={6}>
                                        <Field
                                            name="lastname"
                                            validate={required}
                                            subscription={{
                                                value: true,
                                                active: true,
                                                error: true,
                                                touched: true
                                            }}
                                            defaultValue={info && info.last_name}
                                        >
                                            {({ input, meta }) => (
                                                <label htmlFor="lastname">
                                                    <input
                                                        className={[
                                                            _css.input,
                                                            (meta.error && meta.touched) && variants.error_input
                                                        ].join(" ")}
                                                        type="text"
                                                        id="lastname"
                                                        defaultValue={info && info.last_name}
                                                        onBlur={input.onBlur}
                                                        onChange={input.onChange}
                                                        name={input.name}
                                                        placeholder={"Nom d'utilisateur ou identifiant d'utilisateur"}
                                                    />
                                                </label>
                                            )}
                                        </Field>
                                    </GridItem>
                                    <GridItem lg={12}>
                                        <Field
                                            name="username"
                                            validate={required}
                                            subscription={{
                                                value: true,
                                                active: true,
                                                error: true,
                                                touched: true
                                            }}
                                            defaultValue={info && info.username}
                                        >
                                            {({ input, meta }) => (
                                                <label htmlFor="username">
                                                    <input
                                                        className={[
                                                            _css.input,
                                                            (meta.error && meta.touched) && variants.error_input
                                                        ].join(" ")}
                                                        type="text"
                                                        id="username"
                                                        defaultValue={info && info.username}
                                                        onBlur={input.onBlur}
                                                        onChange={input.onChange}
                                                        name={input.name}
                                                        placeholder={"Nom d'utilisateur"}
                                                    />
                                                </label>
                                            )}
                                        </Field>
                                    </GridItem>
                                    <GridItem lg={12}>
                                        <Field
                                            name="email"
                                            validate={required}
                                            subscription={{
                                                value: true,
                                                active: true,
                                                error: true,
                                                touched: true
                                            }}
                                            defaultValue={info && info.email}
                                        >
                                            {({ input, meta }) => (
                                                <label htmlFor="email">
                                                    <input
                                                        className={[
                                                            _css.input,
                                                            (meta.error && meta.touched) && variants.error_input
                                                        ].join(" ")}
                                                        type="text"
                                                        id="email"
                                                        defaultValue={info && info.email}
                                                        onBlur={input.onBlur}
                                                        onChange={input.onChange}
                                                        name={input.name}
                                                        placeholder={"Address Mail"}
                                                    />
                                                </label>
                                            )}
                                        </Field>
                                    </GridItem>
                                    <GridItem lg={5}>
                                        <Field
                                            name="roles"
                                            component={RolesComponent}
                                            subscription={{
                                                value: true,
                                                active: true,
                                                error: true,
                                                touched: true
                                            }}
                                            value={[]}
                                            multiple
                                            format={value => Array.isArray(value) ? value : []}
                                        />
                                    </GridItem>
                                    <GridItem lg={12}>
                                        <Field
                                            name="gender"
                                            subscription={{
                                                value: true,
                                                active: true,
                                                error: true,
                                                touched: true
                                            }}
                                            defaultValue={info && info.gender !== "M"}
                                            type={"boolean"}
                                            initialValue={info && info.gender !== "M"}
                                        >
                                            {({ input, meta }) => (
                                                <label htmlFor="gender">
                                                    <Switch
                                                        isChecked={input.value}
                                                        labelOff={"Homme"}
                                                        label={"Femme"}
                                                        default={info.gender !== "M"}
                                                        id="gender"
                                                        onBlur={input.onBlur}
                                                        onChange={input.onChange}
                                                        name={input.name}
                                                    />
                                                </label>
                                            )}
                                        </Field>
                                    </GridItem>
                                    <GridItem>
                                        <button type={"submit"}>{loading ?
                                            String("Chargement...")
                                            :String("Envoyer")}</button>
                                    </GridItem>
                                </Grid>
                            </GridItem>
                        </Grid>
                    </form>
                )}
            </FinalForm>
        </React.Fragment>
    )
}

export {AdminUpdateAccountForm};

import {Controller, SubmitHandler, useForm} from "react-hook-form";
import {FC, useState} from "react";
import {
    Alert,
    Button,
    Form,
    FormAlert,
    FormGroup,
    Grid,
    GridItem,
    InputGroup, Text,
    TextContent,
    TextInput
} from "@patternfly/react-core";
import {FaExclamationCircle} from "react-icons/fa";
import {useMutation} from "react-query";
import {EyeIcon, EyeSlashIcon} from "@patternfly/react-icons";
import {z} from "zod";
import {useParams} from "react-router-dom";
import {zodResolver} from "@hookform/resolvers/zod";
import {RESET_PUBLIC_PASSWORD} from "api";

export const resetPasswordFormSchema = z
    .object({
        password: z.string().min(4, { message: 'Le mot de passe doit contenir au moins 4 caractères' }),
        confirmPassword: z.string().min(4, { message: 'La confirmation du mot de passe doit contenir au moins 4 caractères' }),
    })
    .refine(({ password, confirmPassword }) => {
        return password === confirmPassword;
    }, {
        message: "Les mots de passe ne correspondent pas",
        path: ['confirmPassword']
    });


export type resetPasswordFormSchemaTypes = z.infer<typeof resetPasswordFormSchema>;

export const RecoverPublicPasswordForm: FC = () => {

    const {
        control,
        handleSubmit,
        formState: {isValid}
    } = useForm<Readonly<resetPasswordFormSchemaTypes>>({
        mode: "onChange",
        resolver: zodResolver(resetPasswordFormSchema)
    });
    const { token } = useParams();
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const [passwordHidden, setPasswordHidden] = useState<boolean>(false);
    const [confirmPasswordHidden, setConfirmPasswordHidden] = useState<boolean>(false);
    const [successMessage, setSuccessMessage] = useState<string | undefined>(undefined);
    const {
        isSuccess,
        isLoading,
        isError,
        mutateAsync
    } = useMutation({
        mutationFn: RESET_PUBLIC_PASSWORD,
        mutationKey: ["RESET_PUBLIC_PASSWORD"],
        onSuccess: () => {
            setSuccessMessage("Le mot de passe de votre compte a été mis à jour avec succès, rendez-vous sur la page de connexion et accédez à votre compte avec le nouveau mot de passe")
        },
        onError: async () => {
            setErrorMessage("Une erreur s'est produite lors de la tentative de réinitialisation de votre mot de passe, veuillez réessayer, si l'erreur persiste, contactez Banque à pitons pour obtenir de l'aide.")
        }
    });

    const submitForm: SubmitHandler<resetPasswordFormSchemaTypes> = async (data) => {
        await mutateAsync({
            token: token,
            password: data.password,
        })
    };

    return (
        <Form onSubmit={handleSubmit(submitForm)}>
            <FormAlert>
                {(!isLoading && isError) && <Alert variant="danger" isInline isPlain title={errorMessage}/>}
                {(!isLoading && isSuccess) && <Alert variant="success" isInline isPlain title={successMessage}/>}
            </FormAlert>
            <Grid hasGutter>
                <GridItem>
                    <TextContent>
                        <Text component="blockquote">
                            Veuillez remplir les champs ci-dessous avec les informations nécessaires pour créer votre nouveau mot de passe. Assurez-vous de bien le retenir pour éviter d'avoir à refaire le processus.
                        </Text>
                    </TextContent>
                </GridItem>
                <GridItem>
                    <Controller
                        control={control}
                        name="password"
                        key="password"
                        defaultValue={""}
                        rules={{required: true}}
                        render={({
                                     field: {onChange, onBlur, value, name},
                                     fieldState: {error}
                                 }) => (
                            <FormGroup
                                label={"Nouveau mot de passe"}
                                isRequired
                                fieldId={"Nouveau mot de passe"}
                                helperTextInvalid={error?.message}
                                helperTextInvalidIcon={<FaExclamationCircle/>}
                                validated={error ? "error" : "success"}
                            >
                                <InputGroup required>
                                    <TextInput
                                        id={"Nouveau mot de passe"}
                                        aria-label={"Nouveau mot de passe"}
                                        placeholder={"Nouveau mot de passe"}
                                        type={!passwordHidden ? "password" : "text"}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value}
                                        name={name}
                                        validated={error ? "error" : value ? "success" : "default"}
                                        isIconSprite
                                    />
                                    <Button
                                        variant="control"
                                        onClick={() => setPasswordHidden(!passwordHidden)}
                                        aria-label={passwordHidden ? 'Show password' : 'Hide password'}
                                    >
                                        {!passwordHidden ? <EyeIcon/> : <EyeSlashIcon/>}
                                    </Button>
                                </InputGroup>
                            </FormGroup>
                        )}
                    />
                </GridItem>
                <GridItem>
                    <Controller
                        control={control}
                        name="confirmPassword"
                        defaultValue={""}
                        rules={{required: true}}
                        render={({
                                     field: {onChange, onBlur, value, name},
                                     fieldState: {error}
                                 }) => (
                            <FormGroup
                                label={"Confirmez le mot de passe"}
                                isRequired
                                fieldId={"Confirmez le mot de passe"}
                                helperTextInvalid={error?.message}
                                helperTextInvalidIcon={<FaExclamationCircle/>}
                                validated={error ? "error" : "success"}
                            >
                                <InputGroup required>
                                    <TextInput
                                        id={"Confirmez le mot de passe"}
                                        aria-label={"Confirmez le mot de passe"}
                                        placeholder={"Confirmez le mot de passe"}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value}
                                        name={name}
                                        type="password"
                                        validated={error ? "error" : value ? "success" : "default"}
                                    />
                                    <Button
                                        variant="control"
                                        onClick={() => setConfirmPasswordHidden(!passwordHidden)}
                                        aria-label={confirmPasswordHidden ? 'Show password' : 'Hide password'}
                                    >
                                        {!confirmPasswordHidden ? <EyeIcon/> : <EyeSlashIcon/>}
                                    </Button>
                                </InputGroup>
                            </FormGroup>
                        )}
                    />
                </GridItem>
                <GridItem>
                    <Button
                        type="submit"
                        isDisabled={!isValid}
                    >
                        Confirmer
                    </Button>
                </GridItem>
            </Grid>
        </Form>

    )
};
import React, {useCallback, useContext, useEffect, useState} from "react";
import _css from "./SideNav.module.scss";
import {Button, ButtonVariant, Grid, List, ListItem, Title} from "@patternfly/react-core";
import {ArrowLeftIcon, CloseIcon} from "@patternfly/react-icons";
import {useLocation} from "react-router-dom";
import {push} from "react-router-redirect";
import {SessionsContext, SideBarNavContext} from "contexts";
import {SignInForm} from "components";
import {decodeJwtToken} from "modules/security"

interface Props {
}

const SideNav: React.FC<Props> = () => {

    const location = useLocation()
    const {
        navData,
        setNavData
    } = useContext(SideBarNavContext);
    const {
        sess,
        setSess
    } = useContext(SessionsContext);
    const [ login, isLogin ] = useState<boolean>(false);


    const toggleSideBar = () => {
        setNavData({ active : !navData.active })
    }

    useEffect(() => {
        setSess(sess)
        // eslint-disable-next-line
    }, [ sess ])

    const logoutSession = () => {
        localStorage.removeItem("x-access-token")
        setSess(null);
        push("/");
    }

    const toggleSideBarFromSigInForm = useCallback((isOpen) => {
        setNavData({ active : isOpen })
        isLogin(isOpen)
        // eslint-disable-next-line
    }, [ isLogin ])

    return (
        <React.Fragment>
            {navData && navData.active &&
            <div className={_css.container}>
                {login &&
                <React.Fragment>

                    <div className={_css.login_form}>
                        <ArrowLeftIcon className={_css.back_icon} onClick={() => isLogin(false)}/>
                        {!sess &&
                        <SignInForm
                            toggleSideBarFromSigInForm={toggleSideBarFromSigInForm}
                            isNav
                        />}
                        {sess &&
                        <Title
                            size={"4xl"}
                            headingLevel={"h1"}>
                            Salut {decodeJwtToken(sess)["first_name"]}!
                        </Title>}
                    </div>
                </React.Fragment>}
                {!login &&
                <React.Fragment>
                    <List className={_css.options}>
                        <ListItem><CloseIcon onClick={toggleSideBar}/></ListItem>
                        <ListItem
                            className={(location && location.pathname === "/") ? _css.text_list : ""}
                            onClick={() => {
                                push("/")
                                setTimeout(() => toggleSideBar(), 300)
                            }}
                        >
                            <div>Accueil</div>
                        </ListItem>
                        <ListItem
                            className={(location && location.pathname === "/offers") ? _css.text_list : ""}
                            onClick={() => {
                                push("/offers")
                                setTimeout(() => toggleSideBar(), 300)
                            }}
                        >
                            <div>Les Offres</div>
                        </ListItem>
                        <ListItem
                            className={(location && location.pathname === "/pitons.bank") ? _css.text_list : ""}
                            onClick={() => {
                                push("/pitons.bank")
                                setTimeout(() => toggleSideBar(), 300)
                            }}
                        >
                            <div>Banque à pitons</div>
                        </ListItem>
                        <ListItem
                            className={(location && location.pathname === "/joinus") ? _css.text_list : ""}
                            onClick={() => {
                                push("/joinus")
                                setTimeout(() => toggleSideBar(), 300)
                            }}
                        >
                            <div>Nous Joindre</div>
                        </ListItem>
                        <ListItem
                            className={(location && location.pathname === "/blogue.chronics") ? _css.text_list : ""}
                            onClick={() => {
                                push("/blogue.chronics")
                                setTimeout(() => toggleSideBar(), 300)
                            }}
                        >
                            <div>Communiqués</div>
                        </ListItem>
                    </List>
                    <Grid hasGutter className={_css.bottom_buttons}>
                        {!sess &&
                        <button onClick={() => {
                            push("/register/user")
                            setTimeout(() => toggleSideBar(), 300)
                        }}>INSCRIPTION INDIVIDU</button>}
                        {!sess && <button onClick={() => isLogin(true)}>OUVRIR UNE SESSION</button>}
                        {sess &&
                        <Button
                            onClick={logoutSession}
                            isBlock
                            variant={ButtonVariant.link}
                            component={"a"}
                        >Se déconnecter</Button>}
                    </Grid>
                </React.Fragment>}
            </div>
            }
        </React.Fragment>
    )
}

export {SideNav}

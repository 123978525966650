import React from "react";
import cn from "classnames";
import _css from "./styles.module.scss";

interface Props extends React.HTMLProps<HTMLDivElement>{
    className: any
}

const HeaderBox:React.FC<Props> = (props) => {

    const {
        className,
        children
    } = props;


    return (
        <div className={cn(_css.container, className)}>
            {children}
        </div>
    )
};

export default HeaderBox;

import React, {Fragment, useEffect, useRef, useState} from 'react';
import {Field, Form as FinalForm} from "react-final-form";
import arrayMutators from "final-form-arrays";
import _css from "./CreateOffersForm.module.scss";
import {Button, Grid, GridItem, Spinner, Switch} from "@patternfly/react-core";
import variants from "sass/colors.module.scss";
import useRequestFetch from "react-requests-fetch";
import {QUERY_OFFERS, QUERY_OFFERS_CATEGORIES} from "services/urls";

interface Props {
    incrementOffers: React.SetStateAction<any>
}

const CreateOffersForm: React.FC<Props> = ({
                                               incrementOffers
                                           }) => {

    const required = value => (value ? undefined : 'sign-up-form-label.field-required');
    const [ categories, setCategories ] = useState<any>([]);
    const [ reqOffersCategories ] = useRequestFetch({
        uri : QUERY_OFFERS_CATEGORIES,
        params : {
            page : 1,
            limit : 50
        }
    });
    const [ createdOffer, createOffer ] = useRequestFetch();
    const [ isChecked, setIsChecked ] = useState<boolean>(true);
    const formRef = useRef<any>()
    const accessToken = localStorage.getItem("x-imperso") || localStorage.getItem('x-access-token')
    const [ submitting, isSubmitting ] = useState<boolean>(false);

    const handleSubmit = (values) => {
        isSubmitting(true)
        values.category_id = Number(values.category_id);
        values["is_individual"] = isChecked
        createOffer({
            uri : QUERY_OFFERS,
            method : 'POST',
            body : values,
            headers : {
                "x-access-token" : accessToken,
                "Content-Type" : "application/json"
            }
        })
        // formRef.current.reset()
    }

    useEffect(() => {
        (reqOffersCategories && reqOffersCategories.statusCode === 200) &&
        setCategories(reqOffersCategories.payload)
    }, [ reqOffersCategories ]);

    useEffect(() => {
        (createdOffer && createdOffer.statusCode === 201) &&
        incrementOffers(createdOffer.payload)
        isSubmitting(false)
        // eslint-disable-next-line
    }, [ createdOffer ])

    return (
        <Fragment>
            <FinalForm
                onSubmit={handleSubmit}
                subscription={{
                    submitting : true,
                }}
                mutators={{
                    // potentially other mutators could be merged here
                    ...arrayMutators
                }}
                validate={values => {
                    const errors = {};
                    if (!values.title) {
                        errors["title"] = "Required";
                    }
                    if (!values.description) {
                        errors["description"] = "Required";
                    }
                    return errors;
                }}
            >
                {({ handleSubmit }) => (
                    <form ref={formRef} onSubmit={event => {
                        handleSubmit(event);
                    }} className={_css.container}>
                        <Grid hasGutter>
                            <GridItem>
                                <Grid hasGutter className={_css.form_components}>
                                    <GridItem lg={12}>
                                        <Field
                                            name="title"
                                            validate={required}
                                            subscription={{
                                                value : true,
                                                active : true,
                                                error : true,
                                                touched : true
                                            }}
                                        >
                                            {({
                                                  input,
                                                  meta
                                              }) => (
                                                <label htmlFor="title">
                                                    <input
                                                        className={[
                                                            _css.input,
                                                            (meta.error && meta.touched) && variants.error_input
                                                        ].join(" ")}
                                                        type="text"
                                                        id="title"
                                                        onBlur={input.onBlur}
                                                        onChange={input.onChange}
                                                        name={input.name}
                                                        placeholder={"Titre de l'offre"}
                                                    />
                                                </label>
                                            )}
                                        </Field>
                                    </GridItem>
                                    <GridItem lg={12}>
                                        <Field
                                            name="description"
                                            validate={required}
                                            subscription={{
                                                value : true,
                                                active : true,
                                                error : true,
                                                touched : true
                                            }}
                                        >
                                            {({
                                                  input,
                                                  meta
                                              }) => (
                                                <label htmlFor="description">
                                                    <textarea
                                                        className={[
                                                            _css.text_area,
                                                            (meta.error && meta.touched) && variants.error_input
                                                        ].join(" ")}
                                                        id="description"
                                                        onBlur={input.onBlur}
                                                        onChange={input.onChange}
                                                        name={input.name}
                                                        placeholder={"Description de l'offre"}
                                                    />
                                                </label>
                                            )}
                                        </Field>
                                    </GridItem>
                                </Grid>
                            </GridItem>
                            <GridItem>
                                <Field
                                    name="category_id"
                                    validate={required}
                                    subscription={{
                                        value : true,
                                        active : true,
                                        error : true,
                                        touched : true
                                    }}
                                >
                                    {({
                                          input,
                                          meta,
                                      }) => (
                                        <select
                                            defaultValue={(categories && categories.statusCode === 200) ?
                                                categories[0]["id"] : "N"}
                                            className={[
                                                _css.input,
                                                (meta.error && meta.touched) && variants.error_input
                                            ].join(" ")}
                                            onChange={input.onChange}
                                            onBlur={input.onBlur}
                                            name={input.name}
                                        >
                                            <option value="N" disabled>
                                                Sélectionnez la catégorie préférée.
                                            </option>
                                            {categories.map(category =>
                                                <option
                                                    className={_css.options}
                                                    key={category.id}
                                                    value={category.id}
                                                >
                                                    {category.name}
                                                </option>)}
                                        </select>
                                    )}
                                </Field>
                            </GridItem>
                            <GridItem>
                                <Switch
                                    id="individual-switch"
                                    label="L'offre est pour un seul individu."
                                    labelOff="L'offre est collective."
                                    isChecked={isChecked}
                                    onChange={() => setIsChecked(!isChecked)}
                                />
                            </GridItem>
                            <GridItem>
                                {submitting &&
                                <Button isDisabled={submitting} type={"submit"}>
                                    <Spinner size={"sm"}/>
                                </Button>}
                                {!submitting && <Button type={"submit"}>Créer</Button>}
                            </GridItem>
                        </Grid>
                    </form>
                )}
            </FinalForm>
        </Fragment>
    )
}

export default CreateOffersForm;

import React from "react";
import _css from "./SearchInput.module.scss";

interface Props {}

const SearchInput:React.FC<Props> = () => {
    return (
        <React.Fragment>
            <div className={_css.container}>
                <input
                    className={_css.input}
                    type={"text"}
                    placeholder={"Rechercher"}
                />
                <i className="fa fa-search"/>
            </div>
        </React.Fragment>
    )
}

export {SearchInput};

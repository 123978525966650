import { FC, memo, useEffect, useState } from "react"
import styles from "./styles.module.scss";
import { useQuery } from "react-query";
import { API_SEARCH_OFFERS } from "api";


interface Props {
    callback: (value: any) => void
}

export const Service:FC<Props> = memo(({ callback }) => {

    const [ searchValue, setSearchValue ] = useState<string>("");
    const [ selected, setSelected ] = useState<Record<string, any>|undefined>(undefined);
    const [ services, setServices ] = useState<Array<{id: number|string, title: string}>>([]);
    const {
        data,
        isLoading,
        isSuccess
    } = useQuery({
        enabled: searchValue.length > 1,
        queryFn: API_SEARCH_OFFERS,
        queryKey: ["SEARCH_OFFERS", {limit: 10, offset: 1, q: searchValue}],
    });

    const handleChange = (e) => {
        const value = e.target.value || "";
        setSearchValue(value);
    }

    const handleSelect = (value: Record<string, any>) => {
        setSelected(value);
        callback(value)
        setServices([]);
    }

    useEffect(() => {
        if (!isLoading && isSuccess && data) {
            const result = data?.offers.map(({ id, title }) => {
                return {id, title}
            })
            setServices(result)
        }
    }, [isLoading, isSuccess, data])

    return (
        <div className={styles.container}>
            <input
                className={[styles.input].join("")}
                type="text"
                id="service"
                name={"serivce"}
                placeholder={"Service"}
                onChange={(e) => handleChange(e)}
            />
            <ul className={styles.service_block_options}>
            {services.map(({ id, title}) => (
                    <li
                        className={styles.service_block_options_option}
                        key={id}
                        onClick={() => handleSelect({ id, title})}
                    >
                        {title}
                    </li>
                ))}
            </ul>
        </div>
    )
})
import React, {useState} from "react";
import {Field, Form as FinalForm} from "react-final-form";
import arrayMutators from "final-form-arrays";
import _css from "./CreateChronics.module.scss";
import {Button, ButtonVariant, Grid, GridItem, List, ListItem, ListVariant, Text, Title, Alert} from "@patternfly/react-core";
import variants from "sass/colors.module.scss";
import {CREATE_CHRONIC} from "services/urls";
import {FieldArray} from 'react-final-form-arrays';
import {MinusIcon, PlusIcon} from "@patternfly/react-icons";
import {Editor} from '@tinymce/tinymce-react';


interface Props {}


const CreateChronicForm:React.FC<Props> = () => {


    const [message, setMessage] = useState<any|undefined>(undefined);
    const [errorMessage, setError] = useState<any|undefined>(undefined);
    const [ isLoading, setIsLoading ] = useState<boolean>(false);

    const onSubmit = (values) => {
        setIsLoading(true)
        values.content = message
        fetch(CREATE_CHRONIC, {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Origin": "*",
                "Credentials": "same-origin",
                "Content-Type": "application/json",
                "x-access-token": String(localStorage.getItem("x-access-token"))
            },
            mode: "cors",
            cache: "default",
            body: JSON.stringify(values)
        })
            .then((response) => [response.status, response.json()])
            .then((_) => setIsLoading(false))
            .catch((error) => {
                setIsLoading(false);
                setError(error);
            })
    }

    const required = value => (value ? undefined: 'sign-up-form-label.field-required');

    const handleEditorChange = (e) => {
        setMessage(e.target.getContent())
    }

    return (
        <React.Fragment>
            <FinalForm
                onSubmit={onSubmit}
                subscription={{
                    submitting: true,
                }}
                mutators={{
                    // potentially other mutators could be merged here
                    ...arrayMutators
                }}
                validate={values => {
                    const errors = {};
                    if (!values.title) {
                        errors["title"] = "Required";
                    }
                    if (!values.resume) {
                        errors["resume"] = "Required";
                    }
                    return errors;
                }}
            >
                {({handleSubmit}) => (
                    <form onSubmit={event => {
                        // @ts-ignore
                        handleSubmit(event);
                    }} className={_css.container}>
                        <Grid hasGutter>
                            <GridItem>
                                <Title headingLevel={"h1"} className={_css.title}>
                                    Créer une nouvelle chronique.
                                </Title><br/>
                                <Grid hasGutter className={_css.form_components}>
                                    <GridItem lg={12}>
                                        <Field
                                            name="title"
                                            validate={required}
                                            subscription={{
                                                value: true,
                                                active: true,
                                                error: true,
                                                touched: true
                                            }}
                                        >
                                            {({ input, meta }) => (
                                                <label htmlFor="title">
                                                    <input
                                                        className={[
                                                            _css.input,
                                                            (meta.error && meta.touched) && variants.error_input
                                                        ].join(" ")}
                                                        type="text"
                                                        id="title"
                                                        onBlur={input.onBlur}
                                                        onChange={input.onChange}
                                                        name={input.name}
                                                        placeholder={"Titre de la nouvelle"}
                                                    />
                                                </label>
                                            )}
                                        </Field>
                                    </GridItem>
                                    <GridItem lg={12}>
                                        <Field
                                            name="resume"
                                            validate={required}
                                            subscription={{
                                                value: true,
                                                active: true,
                                                error: true,
                                                touched: true
                                            }}
                                        >
                                            {({ input, meta }) => (
                                                <label htmlFor="resume">
                                                    <input
                                                        className={[
                                                            _css.input,
                                                            (meta.error && meta.touched) && variants.error_input
                                                        ].join(" ")}
                                                        type="text"
                                                        id="resume"
                                                        onBlur={input.onBlur}
                                                        onChange={input.onChange}
                                                        name={input.name}
                                                        placeholder={"Resume de la nouvelle"}
                                                    />
                                                </label>
                                            )}
                                        </Field>
                                    </GridItem>
                                </Grid>
                            </GridItem>
                            <GridItem>
                                <Editor
                                    apiKey="osv8722vdzi6swvc8g08x9zwpcn8ylp9nck7qbr2ti8iail5"
                                    init={{
                                        plugins: [
                                            'advlist autolink lists link image',
                                            'charmap print preview anchor help',
                                            'searchreplace visualblocks code',
                                            'insertdatetime media table paste wordcount'
                                        ],
                                        toolbar:
                                            `undo redo | formatselect | bold italic | 
                                            alignleft aligncenter alignright | 
                                            bullist numlist outdent indent | help`,
                                        /* enable title field in the Image dialog*/
                                        image_title: true,
                                        height: 400,
                                        /* enable automatic uploads of images represented by blob or data URIs*/
                                        automatic_uploads: true,
                                        /*
                                          URL of our upload handler (for more details check: https://www.tiny.cloud/docs/configure/file-image-upload/#images_upload_url)
                                          images_upload_url: 'postAcceptor.php',
                                          here we add custom filepicker only to Image dialog
                                        */
                                        file_picker_types: 'image',
                                        /* and here's our custom image picker*/
                                        file_picker_callback: function (cb, value, meta) {
                                            const input = document.createElement('input');
                                            input.setAttribute('type', 'file');
                                            input.setAttribute('accept', 'image/*');

                                            /*
                                              Note: In modern browsers input[type="file"] is functional without
                                              even adding it to the DOM, but that might not be the case in some older
                                              or quirky browsers like IE, so you might want to add it to the DOM
                                              just in case, and visually hide it. And do not forget do remove it
                                              once you do not need it anymore.
                                            */

                                            input.onchange = function () {
                                                // @ts-ignore
                                                const file = this.files[0];

                                                const reader = new FileReader();
                                                reader.onload = function () {
                                                    /*
                                                      Note: Now we need to register the blob in TinyMCEs image blob
                                                      registry. In the next release this part hopefully won't be
                                                      necessary, as we are looking to handle it internally.
                                                    */
                                                    const id = 'blobid' + (new Date()).getTime();
                                                    // @ts-ignore
                                                    const blobCache =  tinymce.activeEditor.editorUpload.blobCache;
                                                    // @ts-ignore
                                                    const base64 = reader.result.split(',')[1];
                                                    const blobInfo = blobCache.create(id, file, base64);
                                                    blobCache.add(blobInfo);

                                                    /* call the callback and populate the Title field with the file name */
                                                    cb(blobInfo.blobUri(), { title: file.name });
                                                };
                                                reader.readAsDataURL(file);
                                            };

                                            input.click();
                                        },
                                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                    }}
                                    onChange={handleEditorChange}
                                />
                            </GridItem>
                            <GridItem>
                                <FieldArray
                                    initialValue={[""]}
                                    name="tags"
                                    multiple
                                >
                                    {({ fields }) => (
                                        <div>
                                            {fields.map((name, index) => (
                                                <div key={name}>
                                                    <div>
                                                        <Text>tag {index + 1}</Text>
                                                        <List variant={ListVariant.inline}>
                                                            <ListItem>
                                                                <Field
                                                                    className={_css.input}
                                                                    name={`${name}`}
                                                                    component="input"
                                                                />
                                                            </ListItem>
                                                            <ListItem>
                                                                <Button
                                                                    variant={ButtonVariant.link}
                                                                    component={"a"}
                                                                    onClick={() => fields.remove(index)}
                                                                >
                                                                    <MinusIcon color={"red"}/>
                                                                </Button>
                                                            </ListItem>
                                                        </List>
                                                    </div>
                                                </div>
                                            ))}
                                            <Button
                                                style={{float: "right"}}
                                                variant={ButtonVariant.tertiary}
                                                onClick={() => fields.push("")}
                                            >
                                                <PlusIcon color={"rgb(118, 189, 29)"}/>&nbsp;Tag
                                            </Button>
                                        </div>
                                    )}
                                </FieldArray>
                            </GridItem>
                            <GridItem>
                                {errorMessage &&
                                <Alert variant="danger" isInline title="Erreur lors de la création du communiqué de presse." />
                                }
                            </GridItem>
                            <GridItem>
                                <Button isDisabled={isLoading} type={"submit"} isLoading={isLoading}>Créer</Button>
                            </GridItem>
                        </Grid>
                    </form>
                )}
            </FinalForm>
        </React.Fragment>
    )
}

export {CreateChronicForm}
